import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { AppState } from './core/models/app-state.interface';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import {SharedModule} from './shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationService} from './core/services/translation.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {CoreModule} from './core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import * as tasksReducer from '@app/features/task/redux/task.reducers';
import * as reportReducer from '@app/features/task/redux/report.reducers';
import * as calendarReducer from '@app/features/calendar/redux/calendar.reducers';
import * as assignmentReducer from '@app/features/assignment/redux/assignment.reducers';
import * as coordsReducer from '@app/features/map/redux/map.reducers';
import * as authReducer from '@app/features/auth/redux/auth.reducers';
import { TaskEffects } from '@app/features/task/redux/task.effects';
import { ReportEffects } from '@app/features/task/redux/report.effects';
import {CalendarEffects} from '@app/features/calendar/redux/calendar.effects';
import {AssignmentEffects} from '@app/features/assignment/redux/assignment.effects';
import {
  MsalModule,
  MsalRedirectComponent,
  MsalGuard,
  MsalInterceptor,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {PublicClientApplication, InteractionType} from '@azure/msal-browser';
import {MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory} from '@app/features/auth/auth.config';
import {AuthEffects} from '@app/features/auth/redux/auth.effects';

export const rootReducer: ActionReducerMap<AppState> = {
  // errorState: errorManagerReducer,
  auth: authReducer.reducer,
  tasks: tasksReducer.reducer,
  report: reportReducer.reducer,
  calendar: calendarReducer.reducer,
  assignments: assignmentReducer.reducer,
  coords: coordsReducer.reducer,
};

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: Action): AppState => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot(rootReducer, {
      metaReducers
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot([
      TaskEffects,
      ReportEffects,
      CalendarEffects,
      AssignmentEffects,
      AuthEffects
    ]),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ReactiveFormsModule,
    NgxSpinnerModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.msal.auth.clientId, // Application (client) ID from the app registration
          authority: environment.msal.auth.authority,
          // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: environment.msal.auth.redirectUri, // This is your redirect URI
        },
        cache: {
          cacheLocation: environment.msal.cache.cacheLocation,
          storeAuthStateInCookie: environment.msal.cache.storeAuthStateInCookie, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [environment.host, ['user.read']],
        ]),
      }
    ),
  ],
  providers: [
    TranslationService,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
