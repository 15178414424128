import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AngularMaterialModule } from './material/angular-material.module';
import { LinkTextComponent } from './link-text/link-text.component';
import { CardDataComponent } from './card-data/card-data.component';
import { TableRowComponent } from './table-row/table-row.component';
import { TableCellComponent } from './table-cell/table-cell.component';
import { TranslateModule } from '@ngx-translate/core';
import { CardLinkComponent } from './card-link/card-link.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalSimpleComponent } from './modal-simple/modal-simple.component';
import { ModalSearchComponent } from './modal-search/modal-search.component';
import { BottomBarStickyComponent } from './bottom-bar-sticky/bottom-bar-sticky.component';
import { LinkWithSubtitleComponent } from './link-with-subtitle/link-with-subtitle.component';
import { BoxAlertComponent } from './box-alert/box-alert.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { ModalOrderComponent } from './modal-order/modal-order.component';
import { FormFieldCheckboxComponent } from './form-field-checkbox/form-field-checkbox.component';
import { FormFieldRadioButtonComponent } from './form-field-radio-button/form-field-radio-button.component';
import { FormFieldSelectSearchComponent } from './form-field-select-search/form-field-select-search.component';
import { FormFieldDateComponent } from './form-field-date/form-field-date.component';
import { BoxTabComponent } from './box-tab/box-tab.component';
import { ModalSendEmailComponent } from './modal-send-email/modal-send-email.component';
import { ModalRadioButtonComponent } from './modal-radio-button/modal-radio-button.component';
import { HttpErrorComponent } from './http-error/http-error.component';
import { DigitalSignatureComponent } from './digital-signature/digital-signature.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './map/map.component';
import { ModalErrorComponent } from '@app/shared/modal-error/modal-error.component';
import { ModalCheckboxesComponent } from '@app/shared/modal-checkboxes/modal-checkboxes.component';
import { ModalCheckboxesNoteComponent } from '@app/shared/modal-checkboxes-note/modal-checkboxes-note.component';
import { ModalNoteComponent } from '@app/shared/modal-note/modal-note.component';
import { ModalPdfViewerComponent } from './modal-pdf-viewer/modal-pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalUploadComponent } from './modal-upload/modal-upload.component';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';


@NgModule({
  declarations: [
    HeaderComponent,
    LinkTextComponent,
    CardDataComponent,
    TableRowComponent,
    TableCellComponent,
    CardLinkComponent,
    ModalSimpleComponent,
    ModalSendEmailComponent,
    ModalRadioButtonComponent,
    ModalCheckboxesComponent,
    ModalCheckboxesNoteComponent,
    ModalNoteComponent,
    ModalSearchComponent,
    ModalOrderComponent,
    BottomBarStickyComponent,
    LinkWithSubtitleComponent,
    BoxAlertComponent,
    SubheaderComponent,
    FormFieldCheckboxComponent,
    FormFieldRadioButtonComponent,
    FormFieldSelectSearchComponent,
    FormFieldDateComponent,
    BoxTabComponent,
    HttpErrorComponent,
    DigitalSignatureComponent,
    MapComponent,
    ModalErrorComponent,
    ModalPdfViewerComponent,
    ModalUploadComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    PdfViewerModule,
    NgxMatFileInputModule
  ],
  exports: [
    HeaderComponent,
    LinkTextComponent,
    CardDataComponent,
    TableRowComponent,
    TableCellComponent,
    CardLinkComponent,
    ModalSimpleComponent,
    ModalSendEmailComponent,
    ModalRadioButtonComponent,
    ModalCheckboxesComponent,
    ModalCheckboxesNoteComponent,
    ModalNoteComponent,
    ModalSearchComponent,
    ModalOrderComponent,
    BottomBarStickyComponent,
    LinkWithSubtitleComponent,
    BoxAlertComponent,
    SubheaderComponent,
    FormFieldCheckboxComponent,
    FormFieldRadioButtonComponent,
    FormFieldSelectSearchComponent,
    FormFieldDateComponent,
    BoxTabComponent,
    HttpErrorComponent,
    DigitalSignatureComponent,
    MapComponent,
    ModalErrorComponent,
    ModalPdfViewerComponent,
  ],
})
export class SharedModule {
}
