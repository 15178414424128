<mat-dialog-content class="pdf-content">
  <div class="modal-pdf-viewer">
    <div class="modal-pdf-viewer__header">
      <h2>{{'modal-pdf-viewer.title'|translate}}</h2>
      <div class="zoom-div">
        <mat-icon *ngIf="zoom !== '0.5'"   (click)="decreaseZoom()" [svgIcon]="'icon-s-minus'|translate">
        </mat-icon>
        <p class="zoom-label">Zoom <span class="zoom-value">{{this.zoom}}</span></p>
        <mat-icon *ngIf="zoom !== '2.00'" (click)="increaseZoom()" [svgIcon]="'icon-s-plus'|translate">
        </mat-icon>
      </div>
      <button class="modal-btn-close" [mat-dialog-close]="true">
        <mat-icon svgIcon="icon-s-close"></mat-icon>
      </button>
    </div>
    <div class="modal-pdf-viewer__content">
      <pdf-viewer [src]="data.pdfUrl"
                  [render-text]="true"
                  [zoom] = "zoom"
                  [original-size]="false"
                  [zoom-scale]="'page-width'"
      ></pdf-viewer>
    </div>
  </div>
</mat-dialog-content>
