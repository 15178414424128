import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {HttpClientService} from '@app/core/services/http-client.service';
import {ErrorManagerService} from '@app/core/services/error-manager.service';
import * as InspectorActions from '@app/features/auth/redux/auth.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EventsResponseInterface} from '@app/core/models/events-response.interface';
import * as apiUrls from '@app/core/pure-functions/api-paths';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {InspectorInterface} from '@app/core/models/inspector.interface';

@Injectable()

export class AuthEffects {
  constructor(
    private actions$: Actions,
    private httpClientService: HttpClientService,
    private errorManager: ErrorManagerService,
  ) {
  }

  inspector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InspectorActions.GetInspector),
      switchMap((action => this.httpClientService.get<InspectorInterface>(apiUrls.INSPECTOR_TYPE)
        .pipe(
          map((response) => ({type: InspectorActions.GET_INSPECTOR_SUCCESS, inspector: response})),
          catchError((err: HttpErrorResponse) => {
            this.errorManager.showErrorDialog(err.message);
            return of();
          })
        ))
      )
    )
  );

}
