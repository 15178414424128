<mat-dialog-content>
  <div class="modal-order">
    <div class="modal-order__header">
      <h2>{{'modal-order.title'|translate}}</h2>
      <button class="modal-btn-close" [mat-dialog-close]="true">
        <mat-icon svgIcon="icon-s-close"></mat-icon>
      </button>
    </div>

    <div class="modal-order__content main-container">
      <p>Disponi dall’alto verso il basso l’ordine di importanza dei paramenti di ordinamento cliccando e trascinando
        l’icona
        Scegli inoltre per ognuno dei paramentri la visualizzazione crescente o decrescente semplicemente cliccando
        sull’icona</p>

      <div cdkDropList class="modal-order__list" (cdkDropListDropped)="drop($event)">
        <div class="modal-order__item-container" *ngFor="let item of tasksOrdering.sorts; index as i" cdkDrag>
          <mat-icon [svgIcon]="'icon-s-menu'"></mat-icon>
          <div class="modal-order__item">
            {{ item.label|translate }}
            <button class="btn btn--secondary" (click)="changeOrdering(item)">
              <mat-icon [svgIcon]="(item.type === 'ASC') ? 'icon-s-order-increasing' : 'icon-s-order-decreasing'"></mat-icon>
<!--              <mat-icon *ngIf="iconOrder" [svgIcon]="'icon-s-order-increasing'"></mat-icon>-->
<!--              <mat-icon *ngIf="!iconOrder" [svgIcon]="'icon-s-order-decreasing'"></mat-icon>-->
            </button>
          </div>
        </div>
      </div>
      <div class="modal-order__footer">
        <button class="btn btn--primary" (click)="setOrdering()">
          <span>{{'modal-order.cta'|translate}}</span>
        </button>
      </div>
    </div>

  </div>
</mat-dialog-content>
