import {createAction, props} from '@ngrx/store';
import {InspectorInterface} from '@app/core/models/inspector.interface';


export const GET_INSPECTOR = '[AUTH] GET INSPECTOR';
export const GET_INSPECTOR_SUCCESS = '[AUTH] GET_INSPECTOR_SUCCESS';

export const GetInspector = createAction(
  GET_INSPECTOR,
);

export const GetInspetorSuccess = createAction(
  GET_INSPECTOR_SUCCESS,
  props<{ inspector: InspectorInterface }>()

);

