import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalSearchComponent} from '../modal-search/modal-search.component';
import {CtaInterface} from '../../core/models/cta.interface';
import {MatDialog} from '@angular/material/dialog';
import {ModalOrderComponent} from '../modal-order/modal-order.component';
@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent  {
  @Input() cta: CtaInterface;
  @Input() backCta: CtaInterface;
  @Input() title: string;
  @Input() styleClass: string;
  @Input() serviceMenu: boolean;
  @Input() assignmentsMenu: boolean;
  // @Input() itemsMenu: ModalSearchInterface | ModalOrderInterface;
  // itemModalSearch: ModalSearchInterface;
  // itemModalOrder: ModalOrderInterface;

  @Output() emitCta = new EventEmitter<any>();
  @Output() emitBackCta = new EventEmitter<any>();

  constructor(private dialog: MatDialog) {
  }


  openModalSearch(): void {
      const dialogRef = this.dialog.open(ModalSearchComponent, {
        maxWidth: '100%',
        width: '100%',
        disableClose: true,
        data: {
          assignments: this.assignmentsMenu,
        }
      });
  }

  openModalOrder(): void {
    const dialogRef = this.dialog.open(ModalOrderComponent, {
      maxWidth: '100%',
      width: '100%',
      disableClose: true,
      // data: this.itemModalOrder
    });
  }

  clickCta(event): void {
    this.emitCta.emit(true);
  }

  clickBackCta(event): void {
    this.emitBackCta.emit(true);
  }

}
