<mat-dialog-content>
  <div class="modal-send-email">
    <div class="modal-send-email__header">
      <p>{{data.title|translate}}</p>
      <button class="modal-btn-close" [mat-dialog-close]="true">
        <mat-icon svgIcon="icon-s-close"></mat-icon>
      </button>
    </div>

    <div class="modal-send-email__content">
      <div [innerHTML]="data.body|translate"></div>
      <form [formGroup]="form" class="form modal-send-email__form">
        <div class="form__tip">* Campi obbligatori</div>
        <ng-container *ngFor="let emailFormGroup of emailControls.controls | slice:0:3; let i = index">
          <div [formGroup]="emailFormGroup" class="modal-send-email__input">
            <mat-form-field floatLabel="always">
              <mat-label>{{data.label|translate}}</mat-label>
              <input formControlName="email"
                     type="email"
                     matInput
                     required="{{(i === 0)}}">
              <!--<mat-error *ngIf="this.form.get('email').hasError('pattern')">-->
              <!--{{'form.errors.pattern' | translate}}-->
              <!--</mat-error>-->
              <!--<mat-error *ngIf="this.form.get('email').hasError('required')">-->
              <!--{{'form.errors.required' | translate}}-->
              <!--</mat-error>-->
            </mat-form-field>
            <button (click)="addFormControl()" class="btn btn--primary modal-send-email__action modal-send-email__action--add">
              <mat-icon svgIcon="icon-s-plus"></mat-icon>
            </button>
            <button [disabled]="(i === 0)" (click)="removeFormControl(i)" class="btn btn--primary modal-send-email__action modal-send-email__action--remove">
              <mat-icon svgIcon="icon-s-minus"></mat-icon>
            </button>
          </div>
        </ng-container>

        <div class="modal-send-email__cta">
          <button [disabled]="sendIsDisabled" class="btn btn--primary" (click)="confirm()">
            <span>{{data.cta.label|translate}}</span>
            <mat-icon [svgIcon]="data.cta.icon|translate"></mat-icon>
          </button>
        </div>
      </form>

    </div>

  </div>
</mat-dialog-content>
