<div class="map-container">
  <google-map
    width="100%"
    [center]="this.center"
    [zoom]="this.zoom"
    [options]="this.options">
    <map-marker
      *ngFor="let marker of markers"
      [position]="marker.position"
      [options]="marker.options">
    </map-marker>
  </google-map>
</div>
