<div class="http-error main-container">

  <ng-container *ngIf="this.httpError.code === 403">
    <div class="http-error__title">
      <h1>4<div class="http-error__circle"></div>3</h1>
    </div>
    <h2>{{'http-error.subtitle-403'|translate}}</h2>
  </ng-container>

  <ng-container *ngIf="this.httpError.code === 404">
    <div class="http-error__title">
      <h1>4<div class="http-error__circle"></div>4</h1>
    </div>
    <h2>{{'http-error.subtitle-404'|translate}}</h2>
  </ng-container>

  <ng-container *ngIf="this.httpError.code === 501">
    <div class="http-error__title">
      <h1>5<div class="http-error__circle"></div>1</h1>
    </div>
    <h2>{{'http-error.subtitle-501'|translate}}</h2>
  </ng-container>


  <div class="http-error__message">
    <div [innerHTML]="this.httpError.message|translate"></div>
  </div>

  <div class="http-error__cta">
    <button [routerLink]="(this.httpError.code === 403) ? '/login' : '/'" class="btn btn--primary">
      <span>{{'http-error.cta'|translate}}</span>
    </button>
  </div>

</div>
