import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableRowInterface} from '../../core/models/table-row.interface';

@Component({
  selector: 'app-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss']
})
export class TableRowComponent {
  @Input() item: TableRowInterface;
  // @Input() toggle: boolean;
  @Output() emitCheckbox = new EventEmitter();

  constructor() {
  }


  emitChange($event): void {
    this.emitCheckbox.emit($event);
  }


}
