import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@app/core/models/app-state.interface';
import { EventsResponseInterface } from '@app/core/models/events-response.interface';
import { CalendarState } from '@app/features/calendar/redux/calendar.reducers';
import { EventResponseInterface } from '@app/core/models/event-response.interface';

export const selectEventsState = createFeatureSelector<AppState, CalendarState>('calendar');

export const getEvents = createSelector(
  selectEventsState,
  (state: CalendarState): EventResponseInterface[] => state.plannedTasks
);
