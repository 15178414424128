<mat-dialog-content>
  <div class="modal-calendar">
    <button class="modal-btn-close" [mat-dialog-close]="false">
      <mat-icon svgIcon="icon-s-close"></mat-icon>
    </button>
    <div class="modal-calendar__content">
      <table>
        <tr>
          <th>{{ 'calendar.modal-labels.id' | translate }}</th>
          <td>{{ data.id }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.start' | translate }}</th>
          <td>{{ data.start | date:'dd/MM/yyyy HH:mm' }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.end' | translate }}</th>
          <td>{{ data.end | date:'dd/MM/yyyy HH:mm' }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.maintainer' | translate }}</th>
          <td>{{ data.maintainer }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.customer' | translate }}</th>
          <td>{{ data.customer }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.administrator' | translate }}</th>
          <td>{{ data.administrator }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.division' | translate }}</th>
          <td>{{ data.division }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.classification' | translate }}</th>
          <td>{{ data.classification }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.checkType' | translate }}</th>
          <td>{{ data.checkType }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.serialNumber' | translate }}</th>
          <td>{{ data.serialNumber }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.factoryNumber' | translate }}</th>
          <td>{{ data.factoryNumber }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.kw' | translate }}</th>
          <td>{{ data.kw }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.plantAddress' | translate }}</th>
          <td [innerHTML]="addressFormatted"></td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.plantCity' | translate }}</th>
          <td>{{ data.plantCity }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.plantProvince' | translate }}</th>
          <td>{{ data.plantProvince }}</td>
        </tr>
        <tr>
          <th>{{ 'calendar.modal-labels.description' | translate }}</th>
          <td [innerHTML]="descriptionFormatted"></td>
        </tr>
        <tr *ngIf="data.notes">
          <th>{{ 'calendar.modal-labels.notes' | translate }}</th>
          <td [innerHTML]="notesFormatted"></td>
        </tr>
<!--        <tr>-->
<!--          <th>{{ 'calendar.modal-labels.state' | translate }}</th>-->
<!--          <td>{{ data.state }}</td>-->
<!--        </tr>-->
      </table>
    </div>
    <div class="modal-calendar__cta">
      <button class="btn btn--primary" (click)="$event" [mat-dialog-close]="true">
        <span>{{ data.ctaModal.modalSimple.ctaPositive.label | translate  }}</span>
      </button>
    </div>
  </div>
</mat-dialog-content>
