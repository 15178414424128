import {Component, Input, OnInit} from '@angular/core';
import {ErrorInterface} from '../../core/models/error.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss']
})
export class HttpErrorComponent implements OnInit {
  @Input() httpError: ErrorInterface;

  constructor(public activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.httpError = data.httpError;
    });
  }

}
