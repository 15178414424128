<mat-dialog-content>
  <div class="modal-search">
    <div class="modal-search__header">
      <h2>{{'modal-search.title'|translate}}</h2>
      <button class="modal-btn-close" [mat-dialog-close]="true">
        <mat-icon svgIcon="icon-s-close"></mat-icon>
      </button>
    </div>
    <form [formGroup]="searchForm" class="form modal-search__form">
      <div class="modal-search__fields-container main-container">

        <div class="modal-search__field">
          <app-form-field-date [label]="'Data pianificazione intervento DA - A'"
                               [formGroup]="planningDateRange">
          </app-form-field-date>
        </div>

        <div class="modal-search__field">
          <app-form-field-date [label]="'Data scadenza intervento DA - A'"
                               [formGroup]="endDateRange">
          </app-form-field-date>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Numero intervento</mat-label>
            <input formControlName="id"
                   placeholder="Inserisci numero intervento"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Nome Amministratore</mat-label>
            <input formControlName="administrator"
                   placeholder="Inserisci nome amministratore impianto"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Cliente</mat-label>
            <input formControlName="customerName"
                   placeholder="Inserisci cliente"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>

        <div class="modal-search__field">
          <app-form-field-select-search [formFieldModel]="this.provinceModel"
                                        [placeholder]="'Seleziona la provincia dell\'impianto'"
                                        [label]="'Provincia dell\'impianto'">
          </app-form-field-select-search>
        </div>

        <div class="modal-search__field">
          <app-form-field-select-search [formFieldModel]="this.cityModel"
                                        [placeholder]="'Seleziona la città dell\'impianto'"
                                        [label]="'Città dell\'impianto'">
          </app-form-field-select-search>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Indirizzo</mat-label>
            <input formControlName="fullAddress"
                   placeholder="Inserisci indirizzo impianto"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Intestatario verbale</mat-label>
            <input formControlName="reportHeader"
                   placeholder="Inserisci intestatario verbale"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Matricola</mat-label>
            <input formControlName="systemId"
                   placeholder="Inserisci matricola"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Numero fabbrica</mat-label>
            <input formControlName="factoryNumber"
                   placeholder="Inserisci numero fabbrica"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Manutentore</mat-label>
            <input formControlName="maintainerName"
                   placeholder="Inserisci manutentore"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>

        <div class="modal-search__field">
          <app-form-field-select-search [formFieldModel]="this.divisionModel"
                                        [placeholder]="'Seleziona divisione'"
                                        [label]="'Divisione'">
          </app-form-field-select-search>
        </div>

        <div class="modal-search__field">
          <app-form-field-select-search [formFieldModel]="this.taskTypeModel"
                                        [placeholder]="'Seleziona tipo di lavoro'"
                                        [label]="'Tipo di lavoro'">
          </app-form-field-select-search>
        </div>

        <div class="modal-search__field">
          <app-form-field-select-search [formFieldModel]="this.classificationModel"
                                        [placeholder]="'Seleziona classificazione'"
                                        [label]="'Classificazione'">
          </app-form-field-select-search>
        </div>

        <div class="modal-search__field">
          <mat-form-field floatLabel="always">
            <mat-label>Descrizione impianto</mat-label>
            <input formControlName="description"
                   placeholder="Inserisci descrizione impianto"
                   type="text"
                   matInput>
          </mat-form-field>
        </div>
        <div class="modal-search__footer">
          <button class="btn btn--secondary" [disabled]="enableSearch" (click)="resetSearchFields()">
            <span>Reset</span>
          </button>
          <button class="btn btn--primary" [disabled]="enableSearch" (click)="search()">
            <span>Cerca</span>
          </button>
          <div class="modal-search__spacer">

          </div>
        </div>
      </div>

    </form>
  </div>
</mat-dialog-content>
