import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  translateService: TranslateService;

  constructor(private http: HttpClient, translateService: TranslateService) {
    this.translateService = translateService;

  }

  public use(lang: string): void {
    // force it
    lang = 'it';

    let params = new HttpParams();
    params = params.set('lang', lang);
    this.http.get('assets/i18n/' + lang + '.json', {params}).subscribe(response => {
      this.translateService.setTranslation(lang, response);
      this.translateService.use(lang);
    }
    // , error => {
    //   this.http.get('assets/i18n/it.json', {params}).subscribe(response => {
    //     this.translateService.setTranslation(lang, response);
    //     this.translateService.use(lang);
    //   });
    // }
    );
  }

  public getValueFromKey(key: string): string {
    return this.translateService.instant(key);
  }

  public getString(key: string): Observable<string> {
    return this.translateService.get(key);
  }

  public getTranslation(lang: string): Observable<any> {
    return this.http.get('assets/i18n/' + lang + '.json') // it load from local relative path
      .pipe(
        map((res: any) => {
          return res.json();
        })
      );

  }
}
