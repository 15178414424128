<mat-dialog-content>
  <div class="modal-checkbox">
    <form [formGroup]="form">
      <div class="modal-checkbox__header">
        <p>{{data.label}}</p>
        <button class="modal-btn-close" [mat-dialog-close]="false">
          <mat-icon svgIcon="icon-s-close"></mat-icon>
        </button>
      </div>
      <div class="modal-checkbox__content">
        <div class="form modal-checkbox__form">
          <mat-list>
            <mat-list-item formArrayName="checkboxes" *ngFor="let item of checkboxesFormArray.controls; let i = index" >
              <mat-checkbox [ngClass]="{'negative-outcome': (data.details[i].negativeOutcome === 'Yes')}"
                [labelPosition]="'after'"
                [formControlName]="i"
                [checked]="(data.details[i].selected === 'Si')">
                {{ data.details[i].value | translate }}</mat-checkbox>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div class="modal-checkbox__footer">
        <button class="btn btn--secondary" (click)="reset()">
          <span>{{ 'modal-checkbox.reset' | translate  }}</span>
        </button>
        <button class="btn btn--primary" (click)="confirm()">
          <span>{{ 'modal-checkbox.confirm' | translate  }}</span>
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
