import {TaskResponseInterface} from '@app/core/models/task-response.interface';
import {Action, createReducer, on} from '@ngrx/store';
import * as TaskActions from './task.actions';
import {TaskInterface} from '@app/core/models/task.interface';
import {TaskSearchInterface} from '@app/core/models/task-search.interface';
import * as moment from 'moment';
import {TaskOrderingItemInterface} from '@app/core/models/task-ordering-item.interface';
import {TaskOrderingInterface} from '@app/core/models/task-ordering.interface';
import {ProvinceInterface} from '@app/core/models/province.interface';
import {CityInterface} from '@app/core/models/city.interface';

export interface TasksState {
  cities: string[];
  classifications: string[];
  divisions: string[];
  provinces: string[];
  taskType: string[];
  interventi: TaskInterface[];
  interventiFiltered?: TaskInterface[];
  searchFilter?: TaskSearchInterface;
  interventiOrdering?: TaskOrderingInterface;
  currentTask?: TaskInterface;
  returnedTaskResult?: boolean;
  rejectedTasksResult?: boolean;
  acceptedTasksResult?: boolean;
  currentProvinces?: ProvinceInterface[];
  selectedCurrentProvince?: ProvinceInterface;
  currentCities?: CityInterface[];
  selectedCurrentCity?: CityInterface;
  currentCaps?: CityInterface[];
  selectedCurrentCap?: CityInterface;
}

export const initialState: TasksState = {
  cities: null,
  classifications: null,
  divisions: null,
  provinces: null,
  taskType: null,
  interventi: null,
  interventiFiltered: null,
  searchFilter: null,
  interventiOrdering: {
    sorts: [
      {label: 'tasks-ordering.end-date', reference: 'endDate', type: 'ASC'},
      {label: 'tasks-ordering.planning-date', reference: 'planningDate', type: 'ASC'},
      {label: 'tasks-ordering.customer', reference: 'customer.name', type: 'ASC'},
      {label: 'tasks-ordering.province', reference: 'address.province', type: 'ASC'},
      {label: 'tasks-ordering.city', reference: 'address.city', type: 'ASC'},
      {label: 'tasks-ordering.maintainer', reference: 'maintainer.name', type: 'ASC'},
      {label: 'tasks-ordering.division', reference: 'division', type: 'ASC'},
    ]
  },
  currentTask: null,
  returnedTaskResult: null,
  rejectedTasksResult: null,
  acceptedTasksResult: null,
  currentCaps: [],
  currentProvinces: [],
  currentCities: [],
  selectedCurrentCap: null,
  selectedCurrentCity: null,
  selectedCurrentProvince: null
};


const tasksReducer = createReducer(
  initialState,
  on(TaskActions.GetTasksSuccess, (state, {response}) => {
    const interventiOrdered = [...response.interventi].sort((left: TaskInterface, right: TaskInterface): number => {
      const leftDate = (left.endDate !== null) ? moment(left.endDate, 'DD/MM/YYYY').toDate().getTime() : 0;
      const rightDate = (right.endDate !== null) ? moment(right.endDate, 'DD/MM/YYYY').toDate().getTime() : 0;
      return leftDate - rightDate;
    });
    return ({
      ...state,
      cities: response.cities,
      classifications: response.classifications,
      divisions: response.divisions,
      provinces: response.provinces,
      taskType: response.taskType,
      interventi: interventiOrdered,
    });
  }),
  on(TaskActions.GetTasksFiltered, (state, {filters}) => ({
    ...state,
    searchFilter: filters,
    interventiFiltered: state.interventi.filter(
      (task: TaskInterface) => {

        let planningDateResult = false;
        if (filters.planningDate) {
          const filterPlanningDateStart = moment(filters.planningDate.split(' ')[0], 'DD/MM/YYYY');
          const filterPlanningDateEnd = moment(filters.planningDate.split(' ')[1], 'DD/MM/YYYY');
          planningDateResult = moment(task.planningDate, 'DD/MM/YYYY').isBetween(filterPlanningDateStart, filterPlanningDateEnd, null, '[]');
        } else {
          planningDateResult = true;
        }
        let endDateResult = false;
        if (filters.endDate) {
          const filterEndDateStart = moment(filters.endDate.split(' ')[0], 'DD/MM/YYYY');
          const filterEndDateEnd = moment(filters.endDate.split(' ')[1], 'DD/MM/YYYY');
          endDateResult = moment(task.endDate, 'DD/MM/YYYY').isBetween(filterEndDateStart, filterEndDateEnd, null, '[]');
        } else {
          endDateResult = true;
        }

        const idResult = (filters.id) ? task.id.toLowerCase().indexOf(filters.id.toLowerCase()) !== -1 : true;
        const administratorResult = (filters.administrator) ? task.administrator.toLowerCase().indexOf(filters.administrator.toLowerCase()) !== -1 : true;
        const customerNameResult = (filters.customerName) ? task.customer.name.toLowerCase().indexOf(filters.customerName.toLowerCase()) !== -1 : true;
        const provinceResult = (filters.province) ? task.address.province.toLowerCase().indexOf(filters.province.toLowerCase()) !== -1 : true;
        const cityResult = (filters.city) ? task.address.city.toLowerCase().indexOf(filters.city.toLowerCase()) !== -1 : true;
        const fullAddressResult = (filters.fullAddress) ? task.address.fullAddress.toLowerCase().indexOf(filters.fullAddress.toLowerCase()) !== -1 : true;
        const reportHeaderResult = (filters.reportHeader) ? task.reportHeader.toLowerCase().indexOf(filters.reportHeader.toLowerCase()) !== -1 : true;
        const systemIdResult = (filters.systemId) ? task.systemId.toLowerCase().indexOf(filters.systemId.toLowerCase()) !== -1 : true;
        const factoryNumberResult = (filters.factoryNumber) ? task.factoryNumber.toLowerCase().indexOf(filters.factoryNumber.toLowerCase()) !== -1 : true;
        const maintainerNameResult = (filters.maintainerName) ? task.maintainer.name.toLowerCase().indexOf(filters.maintainerName.toLowerCase()) !== -1 : true;
        const divisionResult = (filters.division) ? task.division.toLowerCase().indexOf(filters.division.toLowerCase()) !== -1 : true;
        const taskTypeResult = (filters.taskType) ? task.taskType.toLowerCase().indexOf(filters.taskType.toLowerCase()) !== -1 : true;
        const classificationResult = (filters.classification) ? task.classification.toLowerCase().indexOf(filters.classification.toLowerCase()) !== -1 : true;
        const descriptionResult = (filters.description) ? task.description.toLowerCase().indexOf(filters.description.toLowerCase()) !== -1 : true;

        return planningDateResult && endDateResult && idResult && administratorResult && customerNameResult && provinceResult && cityResult && fullAddressResult && reportHeaderResult && systemIdResult && factoryNumberResult && maintainerNameResult && divisionResult && taskTypeResult && classificationResult && descriptionResult;

      }
    )
  })),
  on(TaskActions.SetTaskOrdering, (state, {ordering}) => {
    const orderingSorts = [...ordering.sorts];
    orderingSorts.reverse();
    const interventiFilteredOrdered = [...state.interventiFiltered];
    orderingSorts.map((item: TaskOrderingItemInterface) => {
        switch (item.reference) {
          case 'endDate': {
            interventiFilteredOrdered.sort((left: TaskInterface, right: TaskInterface): number => {
              const leftValue = (left.endDate !== null) ? moment(left.endDate, 'DD/MM/YYYY').toDate().getTime() : 0;
              const rightValue = (right.endDate !== null) ? moment(right.endDate, 'DD/MM/YYYY').toDate().getTime() : 0;
              const endDateOrder = leftValue - rightValue;
              return (item.type === 'ASC') ? endDateOrder : -endDateOrder;
            });
            break;
          }
          case 'planningDate': {
            interventiFilteredOrdered.sort((left: TaskInterface, right: TaskInterface): number => {
              const leftValue = (left.planningDate !== null) ? moment(left.planningDate, 'DD/MM/YYYY').toDate().getTime() : 0;
              const rightValue = (right.planningDate !== null) ? moment(right.planningDate, 'DD/MM/YYYY').toDate().getTime() : 0;
              const planningDateOrder = leftValue - rightValue;
              return (item.type === 'ASC') ? planningDateOrder : -planningDateOrder;
            });
            break;
          }
          case 'customer.name': {
            interventiFilteredOrdered.sort((left: TaskInterface, right: TaskInterface): number => {
              const leftValue = (left.customer.name !== null) ? left.customer.name : '';
              const rightValue = (right.customer.name !== null) ? right.customer.name : '';
              const customerNameOrder = leftValue.localeCompare(rightValue);
              return (item.type === 'ASC') ? customerNameOrder : -customerNameOrder;
            });
            break;
          }
          case 'address.province': {
            interventiFilteredOrdered.sort((left: TaskInterface, right: TaskInterface): number => {
              const leftValue = (left.address.province !== null) ? left.address.province : '';
              const rightValue = (right.address.province !== null) ? right.address.province : '';
              const addressProvinceOrder = leftValue.localeCompare(rightValue);
              return (item.type === 'ASC') ? addressProvinceOrder : -addressProvinceOrder;
            });
            break;
          }
          case 'address.city': {
            interventiFilteredOrdered.sort((left: TaskInterface, right: TaskInterface): number => {
              const leftValue = (left.address.city !== null) ? left.address.city : '';
              const rightValue = (right.address.city !== null) ? right.address.city : '';
              const addressCityOrder = leftValue.localeCompare(rightValue);
              return (item.type === 'ASC') ? addressCityOrder : -addressCityOrder;
            });
            break;
          }
          case 'maintainer.name': {
            interventiFilteredOrdered.sort((left: TaskInterface, right: TaskInterface): number => {
              const leftValue = (left.maintainer.name !== null) ? left.maintainer.name : '';
              const rightValue = (right.maintainer.name !== null) ? right.maintainer.name : '';
              const maintainerNameOrder = leftValue.localeCompare(rightValue);
              return (item.type === 'ASC') ? maintainerNameOrder : -maintainerNameOrder;
            });
            break;
          }
          case 'division': {
            interventiFilteredOrdered.sort((left: TaskInterface, right: TaskInterface): number => {
              const leftValue = (left.division !== null) ? left.division : '';
              const rightValue = (right.division !== null) ? right.division : '';
              const divisionOrder = leftValue.localeCompare(rightValue);
              return (item.type === 'ASC') ? divisionOrder : -divisionOrder;
            });
            break;
          }
        }
      }
    );
    return ({
      ...state,
      interventiOrdering: ordering,
      interventiFiltered: interventiFilteredOrdered,
    });
  }),
  on(TaskActions.ResetTasksFiltered, (state) => ({
    ...state,
    interventiFiltered: state.interventi,
    searchFilter: null,
  })),
  on(TaskActions.ReturnTaskSuccess, (state, response) => ({
    ...state,
  })),
  on(TaskActions.SaveCurrentTask, (state, {task}) => ({
    ...state,
    currentTask: task
  })),
  on(TaskActions.GetCurrentProvincesSuccess, (state, {response}) => ({
    ...state,
    currentProvinces: response
  })),
  on(TaskActions.GetCurrentCitiesSuccess, (state, {response}) => ({
    ...state,
    currentCities: response
  })),
  on(TaskActions.GetCurrentCapsSuccess, (state, {response}) => ({
    ...state,
    currentCaps: response
  })),
  on(TaskActions.SetSelectedCurrentProvince, (state, {province}) => ({
    ...state,
    selectedCurrentProvince: province
  })),
  on(TaskActions.SetSelectedCurrentCity, (state, {city}) => ({
    ...state,
    selectedCurrentCity: city
  })),
  on(TaskActions.SetSelectedCurrentCaps, (state, {cap}) => ({
    ...state,
    selectedCurrentCap: cap
  }))
);

export function reducer(state: TaskResponseInterface | undefined, action: Action): TaskResponseInterface {
  return tasksReducer(state, action);
}
