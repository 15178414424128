import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-box-alert',
  templateUrl: './box-alert.component.html',
  styleUrls: ['./box-alert.component.scss']
})
export class BoxAlertComponent {
  @Input() icon: string;
  @Input() body: string;

  constructor() { }



}
