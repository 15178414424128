<div class="bottom-bar">
  <div class="main-container">
    <button *ngIf="ctaNegative" class="btn btn--secondary" (click)="doACtion(false)">
      <span>{{ctaNegative.label|translate}}</span>
    </button>
    <button *ngIf="ctaPositive" class="btn btn--primary" (click)="doACtion(true)">
      <span>{{ctaPositive.label|translate}}</span>
    </button>
  </div>
</div>
