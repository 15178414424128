import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {HttpClientService} from '@app/core/services/http-client.service';
import {ErrorManagerService} from '@app/core/services/error-manager.service';
import * as EventsActions from './calendar.actions';
import * as apiUrls from '@app/core/pure-functions/api-paths';
import {EventsResponseInterface} from '@app/core/models/events-response.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';

@Injectable()

export class CalendarEffects {
  constructor(
    private actions$: Actions,
    private httpClientService: HttpClientService,
    private errorManager: ErrorManagerService,
  ) {
  }

  events$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsActions.GetEvents),
      switchMap((action => this.httpClientService.get<EventsResponseInterface>(apiUrls.TASKS_PLANNING)
        .pipe(
          map((response) => ({type: EventsActions.GET_EVENTS_SUCCESS, response: response})),
          catchError((err: HttpErrorResponse) => {
            this.errorManager.showErrorDialog(err.message);
            return of({type: EventsActions.GET_EVENTS_ERROR, response: err});
          })
        ))
      )
    )
  );

}
