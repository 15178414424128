<mat-dialog-content>
  <div class="modal-simple">
    <button class="modal-btn-close" [mat-dialog-close]="true">
      <mat-icon svgIcon="icon-s-close"></mat-icon>
    </button>
    <mat-icon svgIcon="icon-s-alert"></mat-icon>
    <div [innerText]="data.message | translate"></div>

    <div class="modal-simple__cta">
      <button class="btn btn--primary" [mat-dialog-close]="true">
        <span>{{ 'modal-error.close' | translate  }}</span>
      </button>
    </div>
  </div>
</mat-dialog-content>
