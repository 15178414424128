<!--<mat-form-field [attr.aria-hidden]="true" tabindex="-1" [floatLabel]="'always'">-->
<!--  <mat-label>Seleziona date</mat-label>-->
<!--  <mat-date-range-input [rangePicker]="picker"-->
<!--                        [max]="max"-->
<!--                        [min]="min"-->
<!--                        (click)="picker.open()"-->
<!--                        (dateChange)="onDataChange($event.value)"-->
<!--                        [formControl]="formControl">-->
<!--    <input matStartDate placeholder="Start date">-->
<!--    <input matEndDate placeholder="End date">-->
<!--  </mat-date-range-input>-->
<!--  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--  <mat-date-range-picker #picker></mat-date-range-picker>-->
<!--</mat-form-field>-->

<mat-form-field appearance="fill" floatLabel="always">
  <mat-label>{{this.label}}</mat-label>
  <mat-date-range-input [rangePicker]="picker"
                        [formGroup]="this.formGroup"
                        (click)="picker.open()">
    <input matStartDate formControlName="startRangeDate" placeholder="DA">
    <input matEndDate formControlName="endRangeDate" placeholder="A">
  </mat-date-range-input>
  <mat-icon [svgIcon]="'icon-s-calendar'"></mat-icon>
<!--  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>



