import {Injectable} from '@angular/core';
import {EventsResponseInterface} from '@app/core/models/events-response.interface';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import { LoginResponseInterface } from '@app/core/models/login-response.interface';
import { EventInterface } from '@app/core/models/event.interface';
import { CtaInterface } from '@app/core/models/cta.interface';
import { ModalSimpleInterface } from '@app/core/models/modal-simple.interface';
import { ModalSendEmailInterface } from '@app/core/models/modal-send-email.interface';
import { EventResponseInterface } from '@app/core/models/event-response.interface';
import * as moment from 'moment';
import {TaskInterface} from '@app/core/models/task.interface';

@Injectable({
  providedIn: 'root'
})

export class CalendarService {

  public subscriptions$: Subscription = new Subscription();

  getAllEvents(unformattedEvents: EventResponseInterface[]): any[] {
    const formattedEvents: any[] = [];
    for (const task of unformattedEvents) {
      const startDate = moment(task.plannedDate, 'DD/MM/YYYY HH:mm').toDate();
      const endDate = (task.end) ? moment(task.end, 'DD/MM/YYYY HH:mm').toDate() : new Date(startDate.getTime() + 3600000);
      const newEventObject: EventInterface = {
        administrator: task.administrator,
        checkType: task.checkType,
        classification: task.classification,
        customer: task.customer,
        description: task.description,
        division: task.division,
        end: endDate,
        factoryNumber: task.factoryNumber,
        id: task.id,
        kw: task.kw,
        maintainer: task.maintainer,
        notes: task.notes,
        start: startDate,
        plantAddress: task.plantAddress,
        plantCity: task.plantCity,
        plantProvince: task.plantProvince,
        serialNumber: task.serialNumber,
        state: task.state,
        isAssigned: false,
        ctaTask: (task.id) ? {
            label: '',
            url: '/lista-interventi/dettaglio-intervento/' + task.id,
            icon: 'icon-s-arrow-right',
          } : null,
        ctaModal: {
          label: '',
          icon: 'icon-s-info',
          modalSimple: {
            ctaPositive: {
              label: 'Chiudi',
            }
          }
        },
        statusClass: ''
      };
      formattedEvents.push(newEventObject);
    }
    return formattedEvents;
  }

  checkifAssigned(events: EventInterface[], tasks: TaskInterface[]): EventInterface[] {
    events.forEach((event) => {
      event.isAssigned = false;
      tasks.forEach((task) =>  {
        if (task.id === event.id) {
          event.isAssigned = true;
        }
      });
    });
    return events;
  }
}
