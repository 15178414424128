import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent {

  constructor(public matDialogRef: MatDialogRef<ModalErrorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { message: string }) {
  }

  close(): void {
    this.matDialogRef.close(false);
  }
}
