import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getTasks } from '@app/features/task/redux/task.selectors';
import { filter } from 'rxjs/operators';
import { MapInterface } from '@app/core/models/map.interface';
import { MapService } from '@app/features/map/services/map.service';
import { CtaInterface } from '@app/core/models/cta.interface';
import { Router } from '@angular/router';
import { TaskInterface } from '@app/core/models/task.interface';
import { ResetCoords, SetCoords } from '@app/features/map/redux/map.actions';
import { HttpClientService } from '@app/core/services/http-client.service';
import { Status } from '@app/core/models/status.enum';


@Component({
  selector: 'app-box-map',
  templateUrl: './box-map.component.html',
  styleUrls: ['./box-map.component.scss']
})
export class BoxMapComponent implements OnDestroy {
  public subscriptions$: Subscription = new Subscription();
  type: string;
  props: MapInterface[];
  centerInput: google.maps.LatLngLiteral;
  cities: string[] = [];
  geocoder = new google.maps.Geocoder();
  backCta: CtaInterface = {
    label: 'Mappa',
    url: 'lista-interventi',
  };
  coordsCounter = 0;
  coordsArr = [];
  loopCounter = 0;

  constructor(
    private store: Store,
    private mapService: MapService,
    private router: Router,
    private http: HttpClientService,
  ) {
    this.store.dispatch(ResetCoords());
    this.subscriptions$.add(this.store.select(getTasks).pipe(
      filter((tasks) => !!tasks)
    ).subscribe((tasks: TaskInterface[]) => {
      // put all task location in this.cities
      for (const task of tasks) {
        if (task.status !== Status.CLOSED) {
          let isAlreadyStored = false;
          for (const city of this.cities) {
            if (task.address.city === city) {
              isAlreadyStored = true;
            }
          }
          if (!isAlreadyStored) {
            this.cities.push(task.address.city);
          }
        }
      }
      this.loopCounter = 0;
      this.coordsArr = [];
      this.coordsCounter = 0;
      this.coordsLoop();
    }));
  }

  coordsLoop(i = this.loopCounter): void {
    setTimeout(() => {
      this.getCoords(i);
      this.loopCounter++;
      if (this.loopCounter < this.cities.length) {
        this.coordsLoop(this.loopCounter);
      }
    }, 700);
  }

  getCoords(i): void {
    this.geocoder.geocode({address: this.cities[i]}, (results, status) => {
      this.coordsCounter = this.coordsCounter + 1;
      if (status === 'OK') {


        const singleCoords = {
          position: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
          options: {
            icon: 'assets/images/icons/icon-l-pin.svg',
          }
        };
        this.coordsArr.push(singleCoords);
        const arrToPush = JSON.parse(JSON.stringify(this.coordsArr));
        this.store.dispatch(SetCoords({coordsArr: arrToPush}));
      } else {
      }
    });
  }

  goBack(value: boolean): void {
    if (value) {
      this.router.navigateByUrl(this.backCta.url);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
