import {Action, createReducer, on} from '@ngrx/store';
import * as MapActions from './map.actions';
import {CoordsInterface} from '@app/core/models/coords.interface';

export const initialState: CoordsInterface[] = [];

const mapReducer = createReducer(
  initialState,
  on(MapActions.ResetCoords, (state) => {
    return ({
      ...state,
      coordsArr: [],
    });
  }),
  on(MapActions.SetCoords, (state, {coordsArr}) => {
    return ({
      ...state,
      coordsArr
    });
  })
);

export function reducer(state: CoordsInterface[], action: Action): CoordsInterface[] {
  return mapReducer(state, action);
}
