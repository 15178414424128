import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState} from '@app/core/models/app-state.interface';
import {TaskInterface} from '@app/core/models/task.interface';
import {TasksState} from '@app/features/task/redux/task.reducers';
import {TaskSearchInterface} from '@app/core/models/task-search.interface';
import {TaskOrderingInterface} from '@app/core/models/task-ordering.interface';
import {ProvinceInterface} from '@app/core/models/province.interface';
import {CityInterface} from '@app/core/models/city.interface';

export const selectTasksState = createFeatureSelector<AppState, TasksState>('tasks');

export const getTasks = createSelector(
  selectTasksState,
  (state: TasksState): TaskInterface[] => state.interventiFiltered
);

export const getTasksUnfiltered = createSelector(
  selectTasksState,
  (state: TasksState): TaskInterface[] => state.interventi
);

export const getCities = createSelector(
  selectTasksState,
  (state: TasksState): string[] => state.cities
);

export const getClassifications = createSelector(
  selectTasksState,
  (state: TasksState): string[] => state.classifications
);

export const getDivisions = createSelector(
  selectTasksState,
  (state: TasksState): string[] => state.divisions
);

export const getProvinces = createSelector(
  selectTasksState,
  (state: TasksState): string[] => state.provinces
);

export const getTaskTypes = createSelector(
  selectTasksState,
  (state: TasksState): string[] => state.taskType
);

export const getSearchTasksFilters = createSelector(
  selectTasksState,
  (state: TasksState): TaskSearchInterface => state.searchFilter
);

export const getTasksOrdering = createSelector(
  selectTasksState,
  (state: TasksState): TaskOrderingInterface => state.interventiOrdering
);

export const getTaskDetail = createSelector(
  selectTasksState,
  (state: TasksState, props): TaskInterface => {
    if (state.interventi) {
      return state.interventi.find((task: TaskInterface) => {
        return task.id === props.id;
      });
    }
    return null;
  }
);

export const getCurrentTask = createSelector(
  selectTasksState,
  (state: TasksState): TaskInterface => state.currentTask
);

export const getCurrentProvinces = createSelector(
  selectTasksState,
  (state: TasksState): ProvinceInterface[] => state.currentProvinces
)

export const getCurrentCities = createSelector(
  selectTasksState,
  (state: TasksState): CityInterface[] => state.currentCities
)

export const getCurrentCaps = createSelector(
  selectTasksState,
  (state: TasksState): CityInterface[] => state.currentCaps
)

export const getSelectedCurrentProvince = createSelector(
  selectTasksState,
  (state: TasksState): ProvinceInterface => state.selectedCurrentProvince
)

export const getSelectedCurrentCity = createSelector(
  selectTasksState,
  (state: TasksState): CityInterface => state.selectedCurrentCity
)

export const getSelectedCurrentCap = createSelector(
  selectTasksState,
  (state: TasksState): CityInterface => state.selectedCurrentCap
)
