import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FieldConfigurationData} from '../../core/models/field-configuration-data';
import {OptionValueInterface} from '../../core/models/option-value.interface';
import {MatSelect} from '@angular/material/select';

@Component({
  selector: 'app-form-field-select-search',
  templateUrl: './form-field-select-search.component.html',
  styleUrls: ['./form-field-select-search.component.scss']
})
export class FormFieldSelectSearchComponent implements OnInit {
  @Output() emitChange = new EventEmitter<any>();
  @Input() formFieldModel: FieldConfigurationData<any>;
  @Input() style = {width: '100%'};
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorMessage: string;
  @Input() readonly: boolean;
  @ViewChild('input') input;

  tmpValue: any;
  @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect;

  constructor() {
  }

  /** list of banks filtered by search keyword */
  get filteredList(): OptionValueInterface<any>[] {
    if (!this.formFieldModel) {
      return [];
    }
    if (!this.formFieldModel.formControl.value) {
      return this.formFieldModel.dataList;
    }
    const object = this.formFieldModel.formControl.value;
    if (!this.formFieldModel.dataList) {
      return [];
    }
    return this.formFieldModel.dataList.filter(value => {
      if (object.label) {
        return value.label.toLowerCase().indexOf(object.label.toLowerCase()) > -1;
      } else {
        return value.label.toLowerCase().indexOf(object.toString().toLowerCase()) > -1;
      }
    });
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    if (this.formFieldModel && this.formFieldModel.dataList) {
      if (this.formFieldModel.formControl.value !== '' && this.formFieldModel.formControl.value) {
        this.formFieldModel.formControl.setValue(this.formFieldModel.formControl.value);
      }
    }
  }

  onSelection(event): void {
    this.formFieldModel.formControl.setValue(event.option.value);
    this.emitChange.emit(event.option.value.value);
  }

  displayFn(value): string {
    if (!value) {
      return '';
    }
    return value.label;
  }

  showAllOptions(): void {
    if (this.formFieldModel.formControl.value) {
      this.formFieldModel.formControl.reset();
    }
  }

  handleClosure(): void {
    if (!this.formFieldModel.formControl.value && this.tmpValue) {
      this.formFieldModel.formControl.setValue(this.tmpValue);
    }
    this.input.nativeElement.blur();
  }

  // focusOut(): void {
  //   const currentValue = this.input.nativeElement.value;
  //   const object = this.formFieldModel.dataList.find((item: OptionValueInterface<any>) => item.label === currentValue);
  //   if (object != null) {
  //     this.formFieldModel.formControl.setValue(object);
  //     this.tmpValue = object;
  //     this.emitChange.emit(object.value);
  //   } else {
  //     if (this.input.required) {
  //       this.formFieldModel.formControl.setErrors({['must-select']: true});
  //     }
  //   }
  // }

  getMessageError(): string {
    const key = Object.keys(this.formFieldModel.formControl.errors)[0];
    return `contact-form.label.${key}`;
  }

  getStyle(): string {
    return JSON.stringify(this.style);
  }

}
