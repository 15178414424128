import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {HttpClientService} from '@app/core/services/http-client.service';
import {ErrorManagerService} from '@app/core/services/error-manager.service';
import * as AssignmentsActions from './assignment.actions';
import * as apiUrls from '@app/core/pure-functions/api-paths';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {AssignmentResponseInterface} from '@app/core/models/assignment-response.interface';

@Injectable()

export class AssignmentEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClientService,
    private errorManager: ErrorManagerService,
  ) {
  }

  $assignments = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActions.GetAssignments),
      switchMap((action => this.http.get<AssignmentResponseInterface>(apiUrls.ASSIGNMENTS)
        .pipe(
          map((response) => ({type: AssignmentsActions.GET_ASSIGNMENTS_SUCCESS, response: response})),
          catchError((err: HttpErrorResponse) => {
            this.errorManager.showErrorDialog(err.message);
            return of({type: AssignmentsActions.GET_ASSIGNMENTS_ERROR, response: err});
          })
        )))
    )
  );
}
