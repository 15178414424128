import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  disabled = false;

  count = 0;

  constructor(private spinner: NgxSpinnerService) { }

  show(): any {
    if ( this.disabled ) {
      return;
    }
    this.spinner.show();
    this.count += 1;
  }
  hide(): any {
    if (this.count > 0) {
      this.count--;
    }
    if (this.count === 0) {
      this.spinner.hide();
    }
  }
  disable(): any {
    this.disabled = true;
  }
  enable(): any {
    this.disabled = false;
  }
}
