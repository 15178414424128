import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {GoogleMap, MapInfoWindow} from '@angular/google-maps';
import {MapInterface} from '../../core/models/map.interface';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {getCoords} from '@app/features/map/redux/map.selectors';
import {filter} from 'rxjs/operators';
import {CoordsInterface} from '@app/core/models/coords.interface';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {
  @ViewChild(GoogleMap, {static: false}) map: GoogleMap;
  @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow;
  @Input() props: MapInterface[];
  @Input() centerInput: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral;
  markers: CoordsInterface[] = [];
  infoContent = '';
  zoom = 8;
  // center: google.maps.LatLngLiteral;
  public subscriptions$: Subscription = new Subscription();
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    styles: [
      {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#d0e3b3'
          }
        ]
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }
    ]
  };

  constructor(
    private store: Store
  ) {
    this.markers = [];
    this.subscriptions$.add(this.store.select(getCoords).pipe(
      filter((coords) => !!coords)
    ).subscribe((coords) => {
      this.markers = coords.coordsArr;
    }));
  }

  ngOnInit(): void {
    this.center = {
      lat: parseFloat('45.513279'),
      lng: parseFloat('9.3000903'),
    };
    // this.addMarker();
  }

  addMarker(): void {
    this.markers.push(
      {
        position: {
          lat: parseFloat('45.513279'),
          lng: parseFloat('9.3000903'),
        },
        options: {
          icon: 'assets/images/icons/icon-l-pin.svg'
        },
      },
        {
          position: {
            lat: parseFloat('45.513289'),
            lng: parseFloat('9.3000909'),
          },
          options: {
            icon: 'assets/images/icons/icon-l-pin.svg'
          },
        },
        {
          position: {
            lat: parseFloat('45.513243'),
            lng: parseFloat('9.3000897'),
          },
          options: {
            icon: 'assets/images/icons/icon-l-pin.svg'
          },
        });
    /*console.log(this.props);

    for (const coord of this.props) {
        const objectToPush = {
          position: {
            lat: parseFloat(coord.latitude),
            lng: parseFloat(coord.longitude),
          },
          options: {
            icon: 'assets/images/icons/icon-l-pin.svg',
          }
        };
        this.markers.push(objectToPush);
      }*/
    }
}

