import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState} from '@app/core/models/app-state.interface';
import {CoordsInterface} from '@app/core/models/coords.interface';


export const selectCoordsState = createFeatureSelector<AppState, {coordsArr: CoordsInterface[]}>('coords');

export const getCoords = createSelector(
  selectCoordsState,
  (state: {coordsArr: CoordsInterface[]}): {coordsArr: CoordsInterface[]} => state
);
