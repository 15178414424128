import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormFieldBase } from '@app/core/models/form-field-base';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-modal-radio-button',
  templateUrl: './modal-note.component.html',
  styleUrls: ['./modal-note.component.scss']
})
export class ModalNoteComponent implements OnInit {
  @Output() emitChange = new EventEmitter<any>();
  form: UntypedFormGroup;

  constructor(public matDialogRef: MatDialogRef<ModalNoteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { label: string, isReturningTask: boolean },
              private fb: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      note: new UntypedFormControl()
    });
  }

  get disableConfirm(): boolean {
    return this.form.controls.note.invalid;
  }

  reset(): void {
    this.form.controls.note.reset();
  }

  confirm(): void {

    this.matDialogRef.close({
      value: this.form.controls.note.value,
    });
  }

}
