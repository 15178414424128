<div class="subheader">
  <div class="main-container">
    <a href="javascript:void(0);" (click)="this.clickBackCta($event)">
      <mat-icon [svgIcon]="'icon-s-chevron-left'"></mat-icon>
      <h2>{{this.backCta?.label}}</h2>
    </a>
    <div class="subheader__service-menu {{this.styleClass}}" *ngIf="this.serviceMenu || this.assignmentsMenu">
      <div class="subheader__service-menu-item" *ngIf="this.serviceMenu || this.assignmentsMenu">
        <a href="javascript:void(0);" (click)="openModalSearch()">
          <span>Cerca</span>
          <mat-icon [svgIcon]="'icon-s-search'"></mat-icon>
        </a>
      </div>
      <div class="subheader__service-menu-item" *ngIf="this.serviceMenu ">
        <a href="javascript:void(0);" (click)="openModalOrder()">
          <span>Ordina</span>
          <mat-icon [svgIcon]="'icon-s-filter'"></mat-icon>
        </a>
      </div>
      <div class="subheader__service-menu-item" *ngIf="this.serviceMenu ">
        <a [routerLink]="'/mappa'">
          <span>Mappa</span>
          <mat-icon [svgIcon]="'icon-s-map'"></mat-icon>
        </a>
      </div>
    </div>

    <ng-container *ngIf="this.cta">
      <div class="subheader__cta-action">
        <button class="btn btn--secondary" (click)="clickCta($event)">
          <span>{{cta.label|translate}}</span>
          <mat-icon [svgIcon]="cta.icon|translate"></mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>
