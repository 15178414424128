import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState} from '@app/core/models/app-state.interface';
import {InspectorInterface} from '@app/core/models/inspector.interface';
import {AuthState} from '@app/features/auth/redux/auth.reducers';


export const selectAuthState = createFeatureSelector<AppState, AuthState>('auth');

export const getInspector = createSelector(
  selectAuthState,
  (state: AuthState): InspectorInterface => state.inspector
);

