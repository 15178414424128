import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CtaInterface} from '../../core/models/cta.interface';
import {ModalSendEmailComponent} from '../modal-send-email/modal-send-email.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalSimpleComponent} from '../modal-simple/modal-simple.component';

@Component({
  selector: 'app-link-text',
  templateUrl: './link-text.component.html',
  styleUrls: ['./link-text.component.scss']
})
export class LinkTextComponent {
  @Input() cta: CtaInterface;
  @Input() styleClass: string;

  @Output() emitModalMailResults = new EventEmitter<string[]>();

  constructor(private dialog: MatDialog) {
  }


  openModalMail(): void {
    const dialogRef = this.dialog.open(ModalSendEmailComponent, {
      maxWidth: '800px',
      width: '100%',
      disableClose: true,
      data: this.cta.modalSendEmail
    });
    dialogRef.afterClosed().subscribe((results) => {
      if (results) {
        // console.log(results);
        this.emitModalMailResults.emit(results);
      }
    });
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ModalSimpleComponent, {
      maxWidth: '800px',
      width: '100%',
      disableClose: true,
      data: this.cta.modalSimple
    });
  }

}
