import {createAction, props} from '@ngrx/store';
import {EventsResponseInterface} from '@app/core/models/events-response.interface';

export const GET_EVENTS = '[EVENT] Events';
export const GET_EVENTS_SUCCESS = '[EVENT] Events success';
export const GET_EVENTS_ERROR = '[EVENT] Events error';

export const GetEventsSuccess = createAction(
  GET_EVENTS_SUCCESS,
  props<{ response: EventsResponseInterface }>()
);

export const GetEvents = createAction(
  GET_EVENTS,
);

export const GetEventsError = createAction(
  GET_EVENTS_ERROR,
  props<{ response: any }>()
);
