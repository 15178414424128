<div class="link-subtitle">
  <a *ngIf="cta.index >= 0; else ctaLink" (click)="this.clickCta($event)">
    <span>{{cta.label}}</span>
    <mat-icon [svgIcon]="'icon-s-chevron-right'"></mat-icon>
  </a>
  <ng-template #ctaLink>
    <a [routerLink]="cta.url">
      <span>{{cta.label}}</span>
      <mat-icon [svgIcon]="'icon-s-chevron-right'"></mat-icon>
    </a>
  </ng-template>
  <p class="counter">{{cta.subtitle}}</p>
</div>
