import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './features/calendar/containers/calendar/calendar.component';
import { BoxMapComponent } from './features/map/containers/box-map/box-map.component';
import { HttpErrorComponent } from '@app/shared/http-error/http-error.component';
import {MsalGuard} from '@azure/msal-angular';
import {AuthGuard} from '@app/features/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [MsalGuard, AuthGuard],
    loadChildren: () => import('./features/home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'lista-interventi',
    canActivateChild: [MsalGuard, AuthGuard],
    loadChildren: () => import('./features/task/task.module').then(mod => mod.TaskModule)
  },
  {
    path: 'incarichi',
    canActivateChild: [MsalGuard, AuthGuard],
    loadChildren: () => import('./features/assignment/assignment.module').then(mod => mod.AssignmentModule)
  },
  {
    path: 'pianificazione-interventi',
    component: CalendarComponent,
    canActivate: [MsalGuard, AuthGuard],
    loadChildren: () => import('./features/calendar/calendar.module').then(mod => mod.CalendarModule)
  },
  {
    path: 'mappa',
    component: BoxMapComponent,
    canActivateChild: [MsalGuard, AuthGuard],
    loadChildren: () => import('./features/map/map.module').then(mod => mod.MapModule)
  },
  {
    path: '403',
    component: HttpErrorComponent,
    canActivate: [MsalGuard],
    data: {
      httpError: {
        code: 403,
        message: 'http-error.message-403',
      }
    },
  },
  {
    path: '404',
    component: HttpErrorComponent,
    canActivate: [MsalGuard],
    data: {
      httpError: {
        code: 404,
        message: 'http-error.message-404',
      }
    },
  },
  {
    path: '501',
    component: HttpErrorComponent,
    canActivate: [MsalGuard],
    data: {
      httpError: {
        code: 501,
        message: 'http-error.message-501',
      }
    },
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
