import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TabInterface} from '../../core/models/tab.interface';

@Component({
  selector: 'app-box-tab',
  templateUrl: './box-tab.component.html',
  styleUrls: ['./box-tab.component.scss']
})
export class BoxTabComponent {
  @Input() items: TabInterface[];
  @Output() emitModalMailData = new EventEmitter<{emails: string[], interventionNumber: string}>();

  constructor() { }



  sendModalMailData(emails: string[], interventionNumber): void {
    // console.log(emails);
    this.emitModalMailData.emit({ emails, interventionNumber} );
  }

}
