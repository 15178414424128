import {AssignmentResponseInterface} from '@app/core/models/assignment-response.interface';
import {Action, createReducer, on} from '@ngrx/store';
import * as AssignmentActions from './assignment.actions';
import {AssignmentStateInterface} from '@app/core/models/assignment-state.interface';
import * as moment from 'moment';
import {TaskInterface} from '@app/core/models/task.interface';
import {AssignmentInterface} from '@app/core/models/assignment.interface';
import {assign} from 'ng2-pdf-viewer/src/app/utils/helpers';

export const initialState: AssignmentStateInterface = {
  filteredAssignments: [],
  assignments: [],
  filters: null,
};

const assignmentsReducer = createReducer(
  initialState,
  on(AssignmentActions.GetAssignmentsSuccess, (state, {response}) => ({
    ...state,
    assignments: response.assignments,
    filteredAssignments: state.filters ? response.assignments.filter(
      (assignment: AssignmentInterface) => {

        let planningDateResult = false;
        if (state.filters.planningDate) {
          const filterPlanningDateStart = moment(state.filters.planningDate.split(' ')[0], 'DD/MM/YYYY');
          const filterPlanningDateEnd = moment(state.filters.planningDate.split(' ')[1], 'DD/MM/YYYY');
          planningDateResult = moment(assignment.planningDate, 'DD/MM/YYYY').isBetween(filterPlanningDateStart, filterPlanningDateEnd, null, '[]');
        } else {
          planningDateResult = true;
        }
        let endDateResult = false;
        if (state.filters.endDate) {
          const filterEndDateStart = moment(state.filters.endDate.split(' ')[0], 'DD/MM/YYYY');
          const filterEndDateEnd = moment(state.filters.endDate.split(' ')[1], 'DD/MM/YYYY');
          endDateResult = moment(assignment.endDate, 'DD/MM/YYYY').isBetween(filterEndDateStart, filterEndDateEnd, null, '[]');
        } else {
          endDateResult = true;
        }

        const idResult = (state.filters.id) ? assignment.id.toLowerCase().indexOf(state.filters.id.toLowerCase()) !== -1 : true;
        const administratorResult = (state.filters.administrator) ? assignment.administrator.toLowerCase().indexOf(state.filters.administrator.toLowerCase()) !== -1 : true;
        const customerNameResult = (state.filters.customerName) ? assignment.customer.name.toLowerCase().indexOf(state.filters.customerName.toLowerCase()) !== -1 : true;
        const provinceResult = (state.filters.province) ? assignment.address.province.toLowerCase().indexOf(state.filters.province.toLowerCase()) !== -1 : true;
        const cityResult = (state.filters.city) ? assignment.address.city.toLowerCase().indexOf(state.filters.city.toLowerCase()) !== -1 : true;
        const fullAddressResult = (state.filters.fullAddress) ? assignment.address.fullAddress.toLowerCase().indexOf(state.filters.fullAddress.toLowerCase()) !== -1 : true;
        const reportHeaderResult = (state.filters.reportHeader) ? assignment.reportHeader.toLowerCase().indexOf(state.filters.reportHeader.toLowerCase()) !== -1 : true;
        const systemIdResult = (state.filters.systemId) ? assignment.systemId.toLowerCase().indexOf(state.filters.systemId.toLowerCase()) !== -1 : true;
        const factoryNumberResult = (state.filters.factoryNumber) ? assignment.factoryNumber.toLowerCase().indexOf(state.filters.factoryNumber.toLowerCase()) !== -1 : true;
        const maintainerNameResult = (state.filters.maintainerName) ? assignment.maintainer.name.toLowerCase().indexOf(state.filters.maintainerName.toLowerCase()) !== -1 : true;
        const divisionResult = (state.filters.division) ? assignment.division.toLowerCase().indexOf(state.filters.division.toLowerCase()) !== -1 : true;
        const taskTypeResult = (state.filters.taskType) ? assignment.taskType.toLowerCase().indexOf(state.filters.taskType.toLowerCase()) !== -1 : true;
        const classificationResult = (state.filters.classification) ? assignment.classification.toLowerCase().indexOf(state.filters.classification.toLowerCase()) !== -1 : true;
        const descriptionResult = (state.filters.description) ? assignment.description.toLowerCase().indexOf(state.filters.description.toLowerCase()) !== -1 : true;

        return planningDateResult && endDateResult && idResult && administratorResult && customerNameResult && provinceResult && cityResult && fullAddressResult && reportHeaderResult && systemIdResult && factoryNumberResult && maintainerNameResult && divisionResult && taskTypeResult && classificationResult && descriptionResult;

      }
    ) : response.assignments
  })),
  on(AssignmentActions.SetAssignmentsFilters, (state, {filters}) => {
    return ({
      ...state,
      filters,
      filteredAssignments: state.assignments.filter(
        (assignment: AssignmentInterface) => {

          let planningDateResult = false;
          if (filters.planningDate) {
            const filterPlanningDateStart = moment(filters.planningDate.split(' ')[0], 'DD/MM/YYYY');
            const filterPlanningDateEnd = moment(filters.planningDate.split(' ')[1], 'DD/MM/YYYY');
            planningDateResult = moment(assignment.planningDate, 'DD/MM/YYYY').isBetween(filterPlanningDateStart, filterPlanningDateEnd, null, '[]');
          } else {
            planningDateResult = true;
          }
          let endDateResult = false;
          if (filters.endDate) {
            const filterEndDateStart = moment(filters.endDate.split(' ')[0], 'DD/MM/YYYY');
            const filterEndDateEnd = moment(filters.endDate.split(' ')[1], 'DD/MM/YYYY');
            endDateResult = moment(assignment.endDate, 'DD/MM/YYYY').isBetween(filterEndDateStart, filterEndDateEnd, null, '[]');
          } else {
            endDateResult = true;
          }

          const idResult = (filters.id) ? assignment.id.toLowerCase().indexOf(filters.id.toLowerCase()) !== -1 : true;
          const administratorResult = (filters.administrator) ? assignment.administrator.toLowerCase().indexOf(filters.administrator.toLowerCase()) !== -1 : true;
          const customerNameResult = (filters.customerName) ? assignment.customer.name.toLowerCase().indexOf(filters.customerName.toLowerCase()) !== -1 : true;
          const provinceResult = (filters.province) ? assignment.address.province.toLowerCase().indexOf(filters.province.toLowerCase()) !== -1 : true;
          const cityResult = (filters.city) ? assignment.address.city.toLowerCase().indexOf(filters.city.toLowerCase()) !== -1 : true;
          const fullAddressResult = (filters.fullAddress) ? assignment.address.fullAddress.toLowerCase().indexOf(filters.fullAddress.toLowerCase()) !== -1 : true;
          const reportHeaderResult = (filters.reportHeader) ? assignment.reportHeader.toLowerCase().indexOf(filters.reportHeader.toLowerCase()) !== -1 : true;
          const systemIdResult = (filters.systemId) ? assignment.systemId.toLowerCase().indexOf(filters.systemId.toLowerCase()) !== -1 : true;
          const factoryNumberResult = (filters.factoryNumber) ? assignment.factoryNumber.toLowerCase().indexOf(filters.factoryNumber.toLowerCase()) !== -1 : true;
          const maintainerNameResult = (filters.maintainerName) ? assignment.maintainer.name.toLowerCase().indexOf(filters.maintainerName.toLowerCase()) !== -1 : true;
          const divisionResult = (filters.division) ? assignment.division.toLowerCase().indexOf(filters.division.toLowerCase()) !== -1 : true;
          const taskTypeResult = (filters.taskType) ? assignment.taskType.toLowerCase().indexOf(filters.taskType.toLowerCase()) !== -1 : true;
          const classificationResult = (filters.classification) ? assignment.classification.toLowerCase().indexOf(filters.classification.toLowerCase()) !== -1 : true;
          const descriptionResult = (filters.description) ? assignment.description.toLowerCase().indexOf(filters.description.toLowerCase()) !== -1 : true;
          console.log(idResult);
          return planningDateResult && endDateResult && idResult && administratorResult && customerNameResult && provinceResult && cityResult && fullAddressResult && reportHeaderResult && systemIdResult && factoryNumberResult && maintainerNameResult && divisionResult && taskTypeResult && classificationResult && descriptionResult;

        }
      )
    });
  }),
  on(AssignmentActions.ResetAssignmentsFilters, (state) => {
    return ({
      assignments: state.assignments,
      filteredAssignments: state.assignments,
      filters: null,
    });
  })
);

export function reducer(state: AssignmentStateInterface | undefined, action: Action): AssignmentStateInterface {
  return assignmentsReducer(state, action);
}
