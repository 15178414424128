import { Action, createReducer, on } from '@ngrx/store';
import * as EventsActions from './calendar.actions';
import { EventResponseInterface } from '@app/core/models/event-response.interface';

export interface CalendarState {
  plannedTasks?: EventResponseInterface[];
}

export const initialstate: CalendarState = {
  plannedTasks: null
};

const calendarReducer = createReducer(
  initialstate,
  on(EventsActions.GetEventsSuccess, (state, {response}) => {
    return ({
      ...state,
      plannedTasks: response.plannedTasks
    });
  })
);

export function reducer(state: CalendarState | undefined, action: Action): CalendarState {
  return calendarReducer(state, action);
}
