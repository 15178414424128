<mat-dialog-content>
  <div class="modal-simple">
    <button class="modal-btn-close" [mat-dialog-close]="false">
      <mat-icon svgIcon="icon-s-close"></mat-icon>
    </button>
    <mat-icon [svgIcon]="data?.icon"></mat-icon>
    <div [innerHTML]="data.body | translate"></div>

    <div class="modal-simple__cta">
      <button *ngIf="data.ctaNegative" class="btn btn--secondary" [mat-dialog-close]="false">
        <span>{{ data.ctaNegative.label | translate }}</span>
      </button>
      <button *ngIf="data.ctaPositive" class="btn btn--primary" (click)="$event" [mat-dialog-close]="true">
        <span>{{ data.ctaPositive.label | translate  }}</span>
      </button>
    </div>
  </div>
</mat-dialog-content>
