<mat-form-field *ngIf="formFieldModel?.formControl" floatLabel="always">
  <mat-label>{{this.label}}</mat-label>
  <input #input
         type="text"
         placeholder="{{this.placeholder}}"
         aria-label="{{this.label}}"
         matInput
         [formControl]="formFieldModel.formControl"
         autocomplete="off"
         required="{{formFieldModel.required}}"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)='onSelection($event)'
                    [displayWith]="displayFn"
                    (opened)="showAllOptions()"
                    (closed)="handleClosure()">
    <mat-option>--</mat-option>
    <mat-option *ngFor="let model of filteredList" [value]="model">
      {{model.label}}
    </mat-option>
  </mat-autocomplete>
  <mat-icon [svgIcon]="'icon-s-chevron-down'"></mat-icon>
  <mat-error *ngIf="formFieldModel?.formControl.invalid">{{this.getMessageError() | translate}}</mat-error>
</mat-form-field>
<!--<mat-form-field *ngIf="formFieldModel?.formControl" [floatLabel]="'always'">-->
<!--  <mat-label>{{this.label}}</mat-label>-->
<!--  <input #input-->
<!--         type="text"-->
<!--         placeholder="{{this.placeholder}}"-->
<!--         aria-label="{{this.label}}"-->
<!--         matInput-->
<!--         required="{{formFieldModel.required}}"-->
<!--         [formControl]="formFieldModel.formControl"-->
<!--         [matAutocomplete]="auto">-->
<!--  <mat-autocomplete #auto="matAutocomplete" (optionSelected)='onSelection($event)'-->
<!--                    [displayWith]="displayFn">-->
<!--    <mat-option *ngFor="let model of filteredList" [value]="model">-->
<!--      {{model.label}}-->
<!--    </mat-option>-->
<!--  </mat-autocomplete>-->
<!--  <mat-error *ngIf="formFieldModel?.formControl.invalid">{{this.getMessageError() | translate}}</mat-error>-->
<!--</mat-form-field>-->
