<app-subheader [title]="'Pianificazioni'" [backCta]="calendarData.backCta" (emitBackCta)="goBack($event)"
               [styleClass]="'no-menu'"></app-subheader>
<div class="calendar main-container">
  <ng-template #customEventTemplate let-weekEvent="weekEvent" let-locale="locale">
    <div class="cal-event {{ (weekEvent.event.division) ? 'cal-event--' + weekEvent.event.division : '' }} {{ weekEvent.event.statusClass }}"
         (click)="eventClicked(weekEvent.event)">
      <div class="label-container">
        <p class="label-container--customer" *ngIf="weekEvent.event.customer">{{weekEvent.event.customer}}</p>
        <p class="label-container--maintainer" *ngIf="weekEvent.event.maintainer">{{weekEvent.event.maintainer}}</p>
        <p class="label-container--administrator" *ngIf="weekEvent.event.administrator">{{weekEvent.event.administrator}}</p>
        <p class="label-container--id" *ngIf="weekEvent.event.id">{{weekEvent.event.id}}</p>
      </div>
      <div class="details-link">
        <mat-icon [svgIcon]="weekEvent.event.ctaModal.icon"
                  (click)="openDetails(weekEvent.event)"></mat-icon>
        <a *ngIf="weekEvent.event.ctaTask  && weekEvent.event.isAssigned" [routerLink]="weekEvent.event.ctaTask.url">
          <mat-icon [svgIcon]="weekEvent.event.ctaTask.icon"></mat-icon>
        </a>
      </div>
    </div>
  </ng-template>
  <div class="calendar__header">
    <div class="calendar__header-col">
      <button
        class="btn btn--secondary"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        [excludeDays]="excludeDays">
        <mat-icon [svgIcon]="'icon-s-chevron-left'"></mat-icon>
      </button>
      <button
        class="btn btn--secondary"
        mwlCalendarToday
        [(viewDate)]="viewDate">
        Oggi
      </button>
      <button
        class="btn btn--secondary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        [excludeDays]="excludeDays">
        <mat-icon [svgIcon]="'icon-s-chevron-right'"></mat-icon>
      </button>
    </div>
    <div class="calendar__header-col">
      <h3>
        {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn:excludeDays | uppercase}}
      </h3>
    </div>
  </div>
  <div class="calendar__content week-view">
    <mwl-calendar-week-view
      [viewDate]="viewDate"
      [dayStartHour]="5"
      [dayEndHour]="22"
      [events]="events"
      [locale]="locale"
      [eventTemplate]="customEventTemplate"
      [excludeDays]="excludeDays">
    </mwl-calendar-week-view>
  </div>
  <div class="calendar__content day-view">
    <mwl-calendar-day-view
      [viewDate]="viewDate"
      [dayStartHour]="5"
      [dayEndHour]="22"
      [events]="events"
      [locale]="locale"
      [eventTemplate]="customEventTemplate">
    </mwl-calendar-day-view>
  </div>
</div>
