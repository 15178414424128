<div class="card-data">
  <div class="card-data__container">
    <div class="card-data__header">
      <div class="card-data__header-row">
        <span>{{'task-labels.id'|translate}}:</span>
        <p>{{item.id}}</p>
      </div>
      <div class="card-data__header-row">
        <span>{{'task-labels.customer-name'|translate}}:</span>
        <p>{{item.name}}</p>
      </div>
    </div>
    <div class="card-data__body">
      <app-table-cell *ngFor="let item of item.items" [item]=item></app-table-cell>
    </div>
    <div class="card-data__footer">
      <app-link-text (emitModalMailResults)="sendModalMailData($event)" [cta]="item.cta" [styleClass]="'white'"></app-link-text>
    </div>
  </div>
</div>
