import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CtaInterface} from '../../core/models/cta.interface';

@Component({
  selector: 'app-bottom-bar-sticky',
  templateUrl: './bottom-bar-sticky.component.html',
  styleUrls: ['./bottom-bar-sticky.component.scss']
})
export class BottomBarStickyComponent {
  @Output() emitChange = new EventEmitter<any>();
  @Input() ctaNegative: CtaInterface;
  @Input() ctaPositive: CtaInterface;

  constructor() { }

  doACtion(value: boolean): void {
    this.emitChange.emit(value);
  }
}
