import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {svgIcons} from './core/models/icon-svg';
import {environment} from '../environments/environment';
import { TranslationService } from '@app/core/services/translation.service';
import {AuthService} from '@app/features/auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  isIframe = false;
  loginDisplay = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslationService,
    private authService: AuthService,
  ) {

    const node = document.createElement('script');
    node.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.mapsApiKey;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);

    svgIcons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });

    this.translate.use(this.translate.translateService.getBrowserLang());
  }

  ngOnInit(): void {
    this.authService.checkLogin();
  }

}
