import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableCellInterface} from '../../core/models/table-cell.interface';
import {PreviewType} from '@app/core/models/preview-type.enum';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss']
})
export class TableCellComponent {
  @Input() item: TableCellInterface;
  @Output() emitCta = new EventEmitter<any>();

  previewType = PreviewType;

  constructor() {
  }


  clickCta(value: string): void {
    this.emitCta.emit(value);
  }
}
