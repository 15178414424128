import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {SpinnerService} from '@app/core/services/spinner.service';
import { AuthService } from '@app/features/auth/services/auth.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private spinner: SpinnerService) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.authService.logout();
      this.authService.checkLogin();
      return of(err.message);
    } else if (err.status === 405) {
      return of(err.message);
    }
    return throwError(() => err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    const req = this.authService.isLoggedIn ? this.addTokenToRequest(request) : request;
    return next.handle(req).pipe(
      finalize(() => this.spinner.hide()),
      catchError((err: HttpErrorResponse) => this.handleAuthError(err))
    );
  }

  private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    return request
      .clone({
        setHeaders: {
          Authorization: this.authService.currentAccessToken,
        }
      });
  }
}
