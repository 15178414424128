import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '@app/core/models/app-state.interface';
import {msalGuardConfig} from '@app/features/auth/auth.config';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthenticationResult, EventMessage, EventType} from '@azure/msal-browser';
import {GetInspector} from '@app/features/auth/redux/auth.actions';
import {getInspector} from '@app/features/auth/redux/auth.selectors';
import {InspectorInterface, InspectorTypeEnum} from '@app/core/models/inspector.interface';
import {Route, Router} from '@angular/router';
import {jwtDecode} from 'jwt-decode';


const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'auth.token';
const INTERNAL_LOCAL_STORAGE_KEY = 'auth.internal';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private name: string;
  private token: string;
  private internal: boolean;
  private readonly _destroying$ = new Subject<void>();
  private subscription$ = new Subscription();

  inspector: InspectorInterface;


  constructor(
              private store: Store<AppState>,
              private msalService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private router: Router
              ) {

    this.token = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    this.internal = JSON.parse(localStorage.getItem(INTERNAL_LOCAL_STORAGE_KEY));

    this.subscription$.add(this.store.select(getInspector).pipe(
      filter((inspector) => !!inspector)
      ).subscribe(inspector => {
        this.inspector = inspector;
        this.internal = inspector.type === InspectorTypeEnum.INTERNO;
        this.name = jwtDecode(this.token.split(' ')[1])['unique_name'];
        console.log(this.name);
        this.router.navigateByUrl('/');
      })
    );

  }

  setToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
    this.token = token;
  }

  get isLoggedIn(): boolean {
    return !!this.token;
  }

  get currentAccessToken(): string {
    return this.token;
  }

  get isInternalUser(): boolean {
    return !!this.internal;
  }

  get username(): string {
    return this.name;
  }

  async checkLogin(_destroying$: Subject<any> = this._destroying$): Promise<void> {
    const configs = msalGuardConfig.authRequest as any;

    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.acquireTokenSilent(configs);
    } else {
      this.msalService.loginRedirect(configs);
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result.error) {
          this.logout();
          this.msalService.loginRedirect(configs);
        } else {
          const payload = result.payload as AuthenticationResult;

          this.msalService.instance.setActiveAccount(payload.account);

          this.setToken(`${payload.tokenType} ${payload.accessToken}`);
          console.log('getting inspector');
          this.store.dispatch(GetInspector());

        }
      });
  }

  async logout(): Promise<void> {
    localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
    this.msalService.logout();
  }



}
