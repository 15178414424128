<div class="main-container">
  <div class="digital-signature" id="signature-pad-container">
    <p class="digital-signature__title">{{ title }}</p>
    <canvas class="signature-pad" id="signature-pad"></canvas>
  </div>
</div>

<div class="digital-signature__cta">
  <button (click)="clearSignature()" [disabled]="signaturePadIsEmpty" class="btn btn--secondary">Cancella firma</button>
  <button (click)="saveSignature()" [disabled]="signaturePadIsEmpty" class="btn btn--primary">Salva firma</button>
</div>

