import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {HttpClientService} from '@app/core/services/http-client.service';
import * as apiUrls from '@app/core/pure-functions/api-paths';
import * as fromActions from './report.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {ErrorManagerService} from '@app/core/services/error-manager.service';
import {Router} from '@angular/router';
import {ReportInterface} from '@app/core/models/report.interface';
import {LocalStorageKeys} from '@app/core/models/local-storage-keys.enum';

@Injectable()
export class ReportEffects {

  report$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GetReport),
      switchMap((action => this.httpClientService.get<ReportInterface>(apiUrls.REPORTS_SECTIONS, {}, action.id)
          .pipe(
            map((response) => ({type: fromActions.GET_REPORT_SUCCESS, response: response})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: fromActions.GET_REPORT_ERROR, response: err});
            })
          )
      ))
    )
  );

  saveReportToServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SaveReport),
      switchMap((action => this.httpClientService.post<string>(apiUrls.REPORTS, action.request, action.id)
          .pipe(
            map((response) => ({type: fromActions.SAVE_REPORT_SUCCESS, response: response})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: fromActions.SAVE_REPORT_ERROR, response: err});
            })
          )
      ))
    )
  );

  saveReportToServerSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.SaveReportSuccess),
        map(() => {
          localStorage.removeItem(LocalStorageKeys.CURRENT_TASK);
          localStorage.removeItem(LocalStorageKeys.CURRENT_REPORT);
          this.router.navigateByUrl('lista-interventi').then();
        })
      ),
    {dispatch: false}
  );

  reportPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GetReportPreview),
      switchMap((action => this.httpClientService.postForPDF<string>(apiUrls.REPORTS_PREVIEW, action.report)
          .pipe(
            map((response) => ({type: fromActions.GET_REPORT_PREVIEW_SUCCESS, response})),
            catchError((err: HttpErrorResponse) => {
              const reader = new FileReader();
              reader.onload = () => {
                try {
                  this.errorManager.showErrorDialog(reader.result as string);
                } catch {
                  const errorText = this.errorManager.formatErrorMessage(err);
                  this.errorManager.showErrorDialog(errorText);
                }
              };
              reader.readAsText(err.error);
              return of({type: fromActions.GET_REPORT_PREVIEW_ERROR, response: err});

            })
          )
      ))
    )
  );

  // reportPreviewSuccess$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(fromActions.GetReportPreviewSuccess),
  //     ),
  //   {dispatch: false}
  // );

  sendReportByMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.SendReportByMail),
      switchMap((action => this.httpClientService.post<string>(apiUrls.REPORTS_SEND_MAIL, action.request, action.id)
          .pipe(
            map((response) => ({type: fromActions.SEND_REPORT_BY_MAIL_SUCCESS, response: response})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: fromActions.SAVE_REPORT_ERROR, response: err});
            })
          )
      ))
    )
  );

  previousReportPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GetPreviousReportPreview),
      switchMap((action => this.httpClientService.getForPDF<string>(apiUrls.REPORTS, {}, action.id)
          .pipe(
            map((response) => ({type: fromActions.GET_PREVIOUS_REPORT_PREVIEW_SUCCESS, response: response})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: fromActions.GET_PREVIOUS_REPORT_PREVIEW_ERROR, response: err});
            })
          )
      ))
    )
  );

  // previousReportPreviewSuccess$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(fromActions.GetPreviousReportPreviewSuccess),
  //     ),
  //   {dispatch: false}
  // );

  constructor(private actions$: Actions,
              private httpClientService: HttpClientService,
              private errorManager: ErrorManagerService,
              private router: Router) {
  }
}
