import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalSimpleInterface} from '../../core/models/modal-simple.interface';
import {CtaInterface} from '../../core/models/cta.interface';

@Component({
  selector: 'app-modal-simple',
  templateUrl: './modal-simple.component.html',
  styleUrls: ['./modal-simple.component.scss']
})
export class ModalSimpleComponent {
  @Output() emitChange = new EventEmitter<any>();
  @Input() ctaNegative: CtaInterface;
  @Input() ctaPositive: CtaInterface;

  constructor(public matDialogRef: MatDialogRef<ModalSimpleComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalSimpleInterface) { }

  close(): void {
    this.matDialogRef.close( false);
  }
}
