export function formatString(str: string, values: string[]) {
  if (values == null) {
    return str;
  }
  for (let index = 0; index < values.length; index++) {
    str = str.replace(`{${index}}`, values[index]);
  }
  return str;
}
export function emptiness(...parameters: string[]): boolean {
  let found = false;
  parameters.forEach(param => {
    if (param === undefined || param === null || param === '') {
      found = true;
    }
  });
  return found;
}
