import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {ModalSendEmailInterface} from '../../core/models/modal-send-email.interface';
import { EMAIL_REGEX } from '@app/core/pure-functions/common-utilities';

@Component({
  selector: 'app-modal-send-email',
  templateUrl: './modal-send-email.component.html',
  styleUrls: ['./modal-send-email.component.scss']
})
export class ModalSendEmailComponent {
  form: UntypedFormGroup;

  constructor(public matDialogRef: MatDialogRef<ModalSendEmailComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalSendEmailInterface,
              private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      emails: this.fb.array([
        this.fb.group({
          email: ['', Validators.pattern(EMAIL_REGEX)]
        })
      ])
    });
  }

  get emailControls(): UntypedFormArray {
    return this.form.controls.emails as UntypedFormArray;
  }

  removeFormControl(i): void {
    const emailsArray = this.form.controls.emails as UntypedFormArray;
    emailsArray.removeAt(i);
  }

  addFormControl(): void {
    const emailsArray = this.form.controls.emails as UntypedFormArray;
    const arraylen = emailsArray.length;

    const newUsergroup: UntypedFormGroup = this.fb.group({
      email: ['', Validators.pattern(EMAIL_REGEX)]
    });

    emailsArray.insert(arraylen, newUsergroup);
  }

  get sendIsDisabled(): boolean {
    const firstFormElement = this.emailControls.at(0) as UntypedFormGroup;
    return firstFormElement.controls.email.invalid;
  }

  reset(): void {
    this.form.controls.note.reset();
  }

  confirm(): void {
    // console.log(this.form.value);
    const results: string[] = [];
    this.form.value.emails.forEach(item => {
      results.push(item.email);
    });
    // console.log(results);
    this.matDialogRef.close(results);
  }

  close(): void {
    this.matDialogRef.close( false);
  }
}
