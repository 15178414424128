<div class="table-cell" [ngClass]="{'address-cell': item.label === 'task-labels.address'}">
  <div class="table-cell__value" *ngIf="item.label">
    <div class="table-cell__label">{{item.label|translate}}</div>
    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="item.label === 'task-labels.previous-report' && item.text !== 'n.d.'">
        <button class="btn btn--secondary" (click)="clickCta(previewType.PREVIOUS)">
          <span>{{'report.preview-button.label'|translate}}</span>
          <mat-icon [svgIcon]="'report.preview-button.icon'|translate"></mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="item.label === 'task-labels.report-filename'">
        <button class="btn btn--secondary" (click)="clickCta(previewType.CURRENT)">
          <span>{{'report.preview-button.label'|translate}}</span>
          <mat-icon [svgIcon]="'report.preview-button.icon'|translate"></mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="item.label === 'task-labels.address'" >
          <p>{{item.text}}</p>
          <a *ngIf="item.icon" class="table-cell__icon" href="http://maps.google.com/maps?q={{item.text}}" target="_blank">
          <mat-icon [svgIcon]="item.icon"></mat-icon>
          </a>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <p>{{item.text}}</p>
      </ng-container>
    </ng-container>


<!--    <ng-container *ngIf="item.label === 'task-labels.previous-report' && item.text !== 'n.d.'; else elseBlock">-->
<!--      <button class="btn btn&#45;&#45;secondary" (click)="clickCta(previewType.PREVIOUS)">-->
<!--        <span>{{'report.preview-button.label'|translate}}</span>-->
<!--        <mat-icon [svgIcon]="'report.preview-button.icon'|translate"></mat-icon>-->
<!--      </button>-->
<!--    </ng-container>-->
<!--    <ng-template #elseBlock>-->
<!--      <p>{{item.text}}</p>-->
<!--    </ng-template>-->
  </div>
  <a *ngIf="item.icon && item.label !== 'task-labels.address'" class="table-cell__icon" href="http://maps.google.com/maps?q={{item.text}}" target="_blank">
    <mat-icon [svgIcon]="item.icon"></mat-icon>
  </a>
</div>
