import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CardLinkInterface} from '../../core/models/card-link.interface';

@Component({
  selector: 'app-card-link',
  templateUrl: './card-link.component.html',
  styleUrls: ['./card-link.component.scss']
})
export class CardLinkComponent {
  @Input() item: CardLinkInterface;

  @Output() selected = new EventEmitter<string>();

  constructor() { }


  onSelected(name: string): void {
    this.selected.emit(name);
  }
}
