import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FieldConfigurationData} from '../../core/models/field-configuration-data';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

export const FORMAT_DATEPICKER = {
  parse: {
    dateInput: [ 'LL'],
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@Component({
  selector: 'app-form-field-date',
  templateUrl: './form-field-date.component.html',
  styleUrls: ['./form-field-date.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: FORMAT_DATEPICKER},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
  ],
})
export class FormFieldDateComponent implements OnInit {
  // @Output() emitChange = new EventEmitter<any>();
  @Input() formFieldModel: FieldConfigurationData<any>;
  @Input() startDate;
  @Input() max;
  @Input() min;
  @Input() errorMessage: string;
  @Input() label: string;
  @Input() placeholder: string;
  // @ViewChild('input', {static: true}) input;
  @Input() formGroup: UntypedFormGroup;

  formControl: UntypedFormControl;

  constructor() {
  }

  ngOnInit(): void {
    this.formControl = this.formFieldModel?.formControl;
  }

  // onDataChange($event): void {
  //   this.emitChange.emit($event);
  // }
}
