import {InspectorInterface} from '@app/core/models/inspector.interface';
import {Action, createReducer, on} from '@ngrx/store';
import * as AuthActions from './auth.actions';

export interface AuthState {
  inspector?: InspectorInterface;
}

export const initialState: AuthState = {
  inspector: null
};


const authReducer = createReducer(
  initialState,
  on(AuthActions.GetInspetorSuccess, (state, {inspector}) => {
    return ({
      ...state,
      inspector,
    });
  })
);

export function reducer(state: AuthState, action: Action): AuthState {
  return authReducer(state, action);
}
