import {createAction, props} from '@ngrx/store';
import {ReportInterface} from '@app/core/models/report.interface';
import {SectionInterface} from '@app/core/models/section.interface';
import {FormFieldBase} from '@app/core/models/form-field-base';
import {MailRequestInterface} from '@app/core/models/mail-request.interface';

export const GET_REPORT = '[REPORT] Get report by id';
export const GET_REPORT_SUCCESS = '[REPORT] Get report by id success';
export const GET_REPORT_ERROR = '[REPORT] Get report by id error';

export const SAVE_CURRENT_REPORT = '[REPORT] Save current report';

export const SET_CURRENT_REPORT_SECTION = '[REPORT] Set current report section';
export const SAVE_TEMPORARY_CURRENT_REPORT_SECTION = '[REPORT] Save temporary current report section';
export const RESET_CURRENT_REPORT_SECTION = '[REPORT] Reset current report section';


export const SET_CURRENT_SIGNATURE_FIELD = '[REPORT] Set current signature field';
export const SAVE_UPDATED_SIGNATURE_FIELD = '[REPORT] Save updated signature field';

export const SAVE_REPORT = '[REPORT] Save report';
export const SAVE_REPORT_SUCCESS = '[REPORT] Save report success';
export const SAVE_REPORT_ERROR = '[REPORT] Save report error';

export const GET_REPORT_PREVIEW = '[REPORT] Get report preview';
export const GET_REPORT_PREVIEW_SUCCESS = '[REPORT] Get report preview success';
export const GET_REPORT_PREVIEW_ERROR = '[REPORT] Get report preview error';
export const CLEAR_REPORT_PREVIEW = '[REPORT] Clear report preview';

export const SEND_REPORT_BY_MAIL = '[REPORT] Send report by mail';
export const SEND_REPORT_BY_MAIL_SUCCESS = '[REPORT] Send report by mail success';
export const SEND_REPORT_BY_MAIL_ERROR = '[REPORT] Send report by mail error';

export const GET_PREVIOUS_REPORT_PREVIEW = '[REPORT] Get previous report preview';
export const GET_PREVIOUS_REPORT_PREVIEW_SUCCESS = '[REPORT] Get previous report preview success';
export const GET_PREVIOUS_REPORT_PREVIEW_ERROR = '[REPORT] Get previous report preview error';
export const CLEAR_PREVIOUS_REPORT_PREVIEW = '[REPORT] Clear previous report preview';

export const GetReport = createAction(
  GET_REPORT,
  props<{ id: string }>()
);
export const GetReportSuccess = createAction(
  GET_REPORT_SUCCESS,
  props<{ response: ReportInterface }>()
);
// export const GetReportError = createAction(
//   GET_REPORT_ERROR,
//   props<{ response: any }>()
// );

export const SaveCurrentReport = createAction(
  SAVE_CURRENT_REPORT,
  props<{ report: ReportInterface }>()
);

export const SetCurrentReportSection = createAction(
  SET_CURRENT_REPORT_SECTION,
  props<{ section: SectionInterface }>()
);

export const SaveTemporaryCurrentReportSection = createAction(
  SAVE_TEMPORARY_CURRENT_REPORT_SECTION,
  props<{ section: SectionInterface }>()
);
export const ResetCurrentReportSection = createAction(
  RESET_CURRENT_REPORT_SECTION,
);

export const SetCurrentSignatureField = createAction(
  SET_CURRENT_SIGNATURE_FIELD,
  props<{ field: FormFieldBase<string> }>()
);

export const SaveUpdatedSignatureField = createAction(
  SAVE_UPDATED_SIGNATURE_FIELD,
  props<{ field: FormFieldBase<string> }>()
);

export const SaveReport = createAction(
  SAVE_REPORT,
  props<{ id: string, request: ReportInterface }>()
);

export const SaveReportSuccess = createAction(
  SAVE_REPORT_SUCCESS,
  props<{ response: any }>()
);
export const SaveReportSuccessError = createAction(
  SAVE_REPORT_ERROR,
  props<{ response: any }>()
);

export const GetReportPreview = createAction(
  GET_REPORT_PREVIEW,
  props<{ report: ReportInterface }>()
);
export const GetReportPreviewSuccess = createAction(
  GET_REPORT_PREVIEW_SUCCESS,
  props<{ response: any }>()
);
export const GetReportPreviewError = createAction(
  GET_REPORT_PREVIEW_ERROR,
  props<{ response: any }>()
);
export const ClearReportPreview = createAction(
  CLEAR_REPORT_PREVIEW,
);

export const SendReportByMail = createAction(
  SEND_REPORT_BY_MAIL,
  props<{ id: string, request: MailRequestInterface }>()
);
export const SendReportByMailSuccess = createAction(
  SEND_REPORT_BY_MAIL_SUCCESS,
  props<{ response: any }>()
);
export const SendReportByMailError = createAction(
  SEND_REPORT_BY_MAIL_ERROR,
  props<{ response: any }>()
);

export const GetPreviousReportPreview = createAction(
  GET_PREVIOUS_REPORT_PREVIEW,
  props<{ id: string }>()
);
export const GetPreviousReportPreviewSuccess = createAction(
  GET_PREVIOUS_REPORT_PREVIEW_SUCCESS,
  props<{ response: any }>()
);
export const GetPreviousReportPreviewError = createAction(
  GET_PREVIOUS_REPORT_PREVIEW_ERROR,
  props<{ response: any }>()
);
export const ClearPreviousReportPreview = createAction(
  CLEAR_PREVIOUS_REPORT_PREVIEW,
);
