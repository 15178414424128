<div class="box-tab">
  <div class="box-tab__container">
    <mat-tab-group animationDuration="0ms">
      <mat-tab *ngFor="let data of this.items" label="{{data.title|translate}} ({{data.interventionNumber}})">
        <app-card-data (emitModalMailData)="sendModalMailData($event, item.id)" *ngFor="let item of data.elements" [item]="item"></app-card-data>
        <app-box-alert *ngIf="data.body" [body]="data.body" [icon]="data.icon"></app-box-alert>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
