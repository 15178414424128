import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '../models/http-response.interface';
import { formatString } from '../pure-functions/string-utilities';
import { HttpOptionsInterface } from '@app/core/models/http-options.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(private http: HttpClient, private log: LoggerService) {
  }


  get<T>(path: string, params?: any, ...parameters: string[]): Observable<T> {
    path = formatString(path, parameters);
    return this.http.get<T>(path, this.addHttpOptions(params));
  }

  post<T>(path: string, body: any, ...parameters: string[]): Observable<T> {
    path = formatString(path, parameters);
    return this.http.post<T>(path, body, this.addHttpOptions());
  }

  getForPDF<T>(path: string, params?: any, ...parameters: string[]): Observable<any> {
    path = formatString(path, parameters);
    return this.http.get(path, {responseType: 'blob'});
  }

  postForPDF<T>(path: string, body: any, ...parameters: string[]): Observable<any> {
    path = formatString(path, parameters);
    return this.http.post(path, body, {responseType: 'blob'});
  }

  put<T>(path: string, body: any, ...parameters: string[]): Observable<HttpResponse<T>> {
    path = formatString(path, parameters);
    return this.http.put<HttpResponse<T>>(path, body, this.addHttpOptions());
  }

  private addHttpOptions(params?: any, responseType?: string, hasToken = true): HttpOptionsInterface {
    return {
      headers: this.getHeaders(responseType, hasToken),
      params: this.getParams(params)
    };
  }

  private getHeaders(responseType: string = 'json', hasToken = true): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/' + responseType
    });
  }

  private getParams(data: any): HttpParams {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key]) {
          params = params.set(key, data[key]);
        }
      });
    }
    return params;
  }
}
