<div class="card-link">
  <div class="card-link__content">
    <div class="card-link__icon">
      <mat-icon [svgIcon]="item.icon|translate"></mat-icon>
    </div>
    <div class="card-link__link">
      <app-link-text [cta]="item.cta" [styleClass]="'white'" (selected)="onSelected($event)"></app-link-text>
    </div>
  </div>
</div>
