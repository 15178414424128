import {createAction, props} from '@ngrx/store';
import {TaskResponseInterface} from '@app/core/models/task-response.interface';
import {TaskSearchInterface} from '@app/core/models/task-search.interface';
import {TaskOrderingInterface} from '@app/core/models/task-ordering.interface';
import {AssignmentRequestInterface} from '@app/core/models/assignment-request.interface';
import {AcceptAssignmentResponseInterface} from '@app/core/models/accept-assignment-response.interface';
import {TaskInterface} from '@app/core/models/task.interface';
import {ProvinceInterface} from '@app/core/models/province.interface';
import {CityInterface} from '@app/core/models/city.interface';

export const GET_TASKS = '[TASK] Tasks';
export const GET_TASKS_SUCCESS = '[TASK] Tasks success';
export const GET_TASKS_ERROR = '[TASK] Tasks error';

export const GET_TASKS_FILTERED = '[TASK] Tasks filtered';
export const RESET_TASKS_FILTERED = '[TASK] Reset tasks filtered';
export const SET_TASKS_ORDERING = '[TASK] Set tasks ordering';

export const RETURN_TASK = '[TASK] Return task';
export const RETURN_TASK_SUCCESS = '[TASK] Return task success';
export const RETURN_TASK_ERROR = '[TASK] Return task error';

export const REJECT_TASKS = '[TASK] Reject tasks';
export const REJECT_TASKS_SUCCESS = '[TASK] Reject tasks success';
export const REJECT_TASKS_ERROR = '[TASK] Reject tasks error';

export const ACCEPT_TASKS = '[TASK] Accept tasks';
export const ACCEPT_TASKS_SUCCESS = '[TASK] Accept tasks success';
export const ACCEPT_TASKS_ERROR = '[TASK] Accept tasks error';


export const GET_CURRENT_PROVINCES = '[TASK] Get Current Provinces';
export const GET_CURRENT_PROVINCES_SUCCESS ='[TASK] Get Current Provinces Success';
export const SET_SELECTED_CURRENT_PROVINCE = '[TASK] Set Selected Current Province';

export const GET_CURRENT_CITIES = '[TASK] Get Current Cities';
export const GET_CURRENT_CITIES_SUCCESS = '[TASK] Get Current Cities Success';
export const SET_SELECTED_CURRENT_CITY = '[TASK] Set Selected Current City';

export const GET_CURRENT_CAPS = '[TASK] Get Current Caps';
export const GET_CURRENT_CAPS_SUCCESS = '[TASK] Get Current Caps Success';
export const SET_SELECTED_CURRENT_CAP = '[TASK] Set Selected Current Cap'


export const SAVE_CURRENT_TASK = '[TASK] Save current task';
// export const GET_CURRENT_TASK = '[TASK] Get current task';

export const GetTasks = createAction(
  GET_TASKS,
);
export const GetTasksSuccess = createAction(
  GET_TASKS_SUCCESS,
  props<{ response: TaskResponseInterface }>()
);
export const GetTasksError = createAction(
  GET_TASKS_ERROR,
  props<{ response: any }>()
);

export const GetTasksFiltered = createAction(
  GET_TASKS_FILTERED,
  props<{ filters: TaskSearchInterface }>()
);
export const SetTaskOrdering = createAction(
  SET_TASKS_ORDERING,
  props<{ ordering: TaskOrderingInterface }>()
);
export const ResetTasksFiltered = createAction(
  RESET_TASKS_FILTERED,
);

export const ReturnTask = createAction(
  RETURN_TASK,
  props<{ request: AssignmentRequestInterface }>()
);
export const ReturnTaskSuccess = createAction(
  RETURN_TASK_SUCCESS,
  props<{ response: AcceptAssignmentResponseInterface, request: AssignmentRequestInterface }>()
);
export const ReturnTaskError = createAction(
  RETURN_TASK_ERROR,
  props<{ response: any }>()
);

export const RejectTasks = createAction(
  REJECT_TASKS,
  props<{ request: AssignmentRequestInterface }>()
);
export const RejectTasksSuccess = createAction(
  REJECT_TASKS_SUCCESS,
  props<{ response: AcceptAssignmentResponseInterface }>()
);
export const RejectTaskError = createAction(
  REJECT_TASKS_ERROR,
  props<{ response: any }>()
);

export const AcceptTasks = createAction(
  ACCEPT_TASKS,
  props<{ request: AssignmentRequestInterface }>()
);
export const AcceptTasksSuccess = createAction(
  ACCEPT_TASKS_SUCCESS,
  props<{ response: AcceptAssignmentResponseInterface }>()
);
export const AcceptTaskError = createAction(
  ACCEPT_TASKS_ERROR,
  props<{ response: any }>()
);

export const SaveCurrentTask = createAction(
  SAVE_CURRENT_TASK,
  props<{ task: TaskInterface }>()
);

export const GetCurrentProvinces = createAction(
  GET_CURRENT_PROVINCES,
)

export const GetCurrentProvincesSuccess = createAction(
  GET_CURRENT_PROVINCES_SUCCESS,
  props<{response: ProvinceInterface[]}>()
)

export const SetSelectedCurrentProvince = createAction(
  SET_SELECTED_CURRENT_PROVINCE,
  props<{province: ProvinceInterface}>()
)

export const GetCurrentCities = createAction(
  GET_CURRENT_CITIES,
  props<{province: string}>()
)

export const GetCurrentCitiesSuccess = createAction(
  GET_CURRENT_CITIES_SUCCESS,
  props<{response: CityInterface[]}>()
)

export const SetSelectedCurrentCity = createAction(
  SET_SELECTED_CURRENT_CITY,
  props<{city: CityInterface}>()
)

export const GetCurrentCaps = createAction(
  GET_CURRENT_CAPS,
  props<{city: string, province: string}>()
)

export const GetCurrentCapsSuccess = createAction(
  GET_CURRENT_CAPS_SUCCESS,
  props<{response: CityInterface[]}>()
)

export const SetSelectedCurrentCaps = createAction(
  SET_SELECTED_CURRENT_CAP,
  props<{cap: CityInterface}>()
)

// export const GetCurrentTask = createAction(
//   GET_CURRENT_TASK,
//   props<{ id: string }>()
// );

