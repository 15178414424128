import {createAction, props} from '@ngrx/store';
import {CoordsInterface} from '@app/core/models/coords.interface';

export const SET_COORDS = '[COORDS] Set coords';
export const RESET_COORDS = '[COORDS] Reset coords';

export const ResetCoords = createAction(
  RESET_COORDS,
);

export const SetCoords = createAction(
  SET_COORDS,
  props<{ coordsArr: CoordsInterface[] }>()
);




