import { DetailInterface } from '@app/core/models/detail.interface';

export class FormFieldBase<T> {
  value: T;
  parameterId: string;
  rowNumber: string;
  label: string;
  required: boolean;
  readonly: boolean;
  controlType: string;
  details: DetailInterface[];
  activationRowValue: string;
  maxLength?: string;
  activationRowNumber: string;
  replicableValues: string;
  isDisabled: boolean;
  noPastDates: boolean;
  noFutureDates: boolean;
  isNoDot: boolean;
  inlineRadio: boolean;
  dataType:string;

  constructor(options: {
    value?: T;
    parameterId?: string;
    rowNumber?: string;
    label?: string;
    required?: boolean;
    readonly?: boolean;
    controlType?: string;
    details?: DetailInterface[];
    activationRowValue?: string;
    maxLength?: string;
    activationRowNumber?: string;
    replicableValues?: string;
    isDisabled?: boolean;
    noPastDates?: boolean;
    noFutureDates?: boolean;
    isNoDot?: boolean;
    inlineRadio?: boolean;
    dataType?: string
  } = {}) {
    this.value = options.value;
    this.parameterId = options.parameterId || '';
    this.rowNumber = options.rowNumber || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.readonly = !!options.readonly;
    this.controlType = options.controlType || '';
    this.maxLength = options.maxLength || '';
    this.details = options.details || [];
    this.activationRowValue = options.activationRowValue || '';
    this.activationRowNumber = options.activationRowNumber || '';
    this.replicableValues = options.replicableValues || '';
    this.isDisabled = !!options.isDisabled;
    this.noPastDates = !!options.noPastDates;
    this.noFutureDates = !!options.noFutureDates;
    this.isNoDot = !!options.isNoDot;
    this.inlineRadio = !!options.inlineRadio;
    this.dataType = options.dataType || ''
  }
}
