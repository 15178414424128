import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-pdf-viewer',
  templateUrl: './modal-pdf-viewer.component.html',
  styleUrls: ['./modal-pdf-viewer.component.scss']
})
export class ModalPdfViewerComponent {

  constructor(public matDialogRef: MatDialogRef<ModalPdfViewerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { pdfUrl: string }) {
  }

  zoom = '1.00';

  increaseZoom(): void {
    switch (this.zoom){
      case '0.5':
        this.zoom = '0.75';
        return;
      case '0.75':
        this.zoom = '1.00';
        return;
      case '1.00':
        this.zoom = '1.50';
        return;
      case'1.50':
        this.zoom = '2.00';
        return;
    }
  }

  decreaseZoom(): void {
    switch (this.zoom) {
      case '0.75':
        this.zoom = '0.5';
        return;
      case '1.00':
        this.zoom = '0.75';
        return;
      case'1.50':
        this.zoom = '1.00';
        return;
      case '2.00':
        this.zoom = '1.50';
        return;
    }
  }


  close(): void {
    this.matDialogRef.close(false);
  }

}
