export enum InspectorTypeEnum {
  INTERNO = 'Interno',
  ESTERNO = 'Esterno',
  ALTRO = 'Altro'
}

export interface InspectorInterface {
  type: InspectorTypeEnum;
}

