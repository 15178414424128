import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormFieldBase } from '@app/core/models/form-field-base';
import { ModalRadioButtonInterface } from '@app/core/models/modal-radio-button.interface';

@Component({
  selector: 'app-modal-radio-button',
  templateUrl: './modal-radio-button.component.html',
  styleUrls: ['./modal-radio-button.component.scss']
})
export class ModalRadioButtonComponent implements OnInit {
  form: UntypedFormGroup;
  @Input() items: ModalRadioButtonInterface;

  constructor(public matDialogRef: MatDialogRef<ModalRadioButtonComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FormFieldBase<string>,
              private fb: UntypedFormBuilder) {
  }

  get disableConfirm(): boolean {
    return this.form.controls.choice.invalid;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      choice: new UntypedFormControl()
    });
  }

  reset(): void {
    this.form.controls.choice.reset();
  }

  confirm(): void {
    this.matDialogRef.close({
      value: this.form.controls.choice.value,
      rowNumber: this.data.rowNumber
    });
  }
}
