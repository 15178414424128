import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CardDataInterface} from '../../core/models/card-data.interface';

@Component({
  selector: 'app-card-data',
  templateUrl: './card-data.component.html',
  styleUrls: ['./card-data.component.scss']
})
export class CardDataComponent {
  @Input() item: CardDataInterface;
  @Output() emitModalMailData = new EventEmitter<string[]>();

  constructor() {
  }

  sendModalMailData(emails: string[]): void {
    // console.log(emails);
    this.emitModalMailData.emit(emails);
  }

}
