<mat-dialog-content>
  <div class="modal-note">
    <form [formGroup]="form">
      <div class="modal-note__header">
        <p>{{data.label|translate}}</p>
        <button class="modal-btn-close" [mat-dialog-close]="false">
          <mat-icon svgIcon="icon-s-close"></mat-icon>
        </button>
      </div>
      <div class="modal-note__content">
        <div class="form modal-note__form">
          <mat-form-field floatLabel="always">
            <mat-label>Note</mat-label>
            <textarea formControlName="note" required matInput maxlength="{{this.data.isReturningTask ? 100 : null}}"></textarea>
          </mat-form-field>
          <p *ngIf="this.data.isReturningTask">Max 100 caratteri</p>
        </div>
      </div>
      <div class="modal-note__footer">
        <button class="btn btn--secondary" (click)="reset()">
          <span>{{ 'modal-note.reset' | translate  }}</span>
        </button>
        <button [disabled]="disableConfirm" class="btn btn--primary" (click)="confirm()">
          <span>{{ 'modal-note.confirm' | translate  }}</span>
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
