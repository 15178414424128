import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-form-field-checkbox',
  templateUrl: './form-field-checkbox.component.html',
  styleUrls: ['./form-field-checkbox.component.scss']
})
export class FormFieldCheckboxComponent implements OnInit {
  @Output() emitChange = new EventEmitter<any>();
  checkedInput = false;
  @Input() label: string;
  @Input() selected: boolean;

  constructor() { }

  ngOnInit(): void {
    this.checkedInput = this.selected;
  }

  checkChange(event): void {
    this.checkedInput = event.checked;
    this.emitChange.emit(this.checkedInput);
  }

}
