<mat-dialog-content>
  <div class="modal-upload">
    <button class="modal-btn-close" [mat-dialog-close]="false">
      <mat-icon svgIcon="icon-s-close"></mat-icon>
    </button>
    <mat-icon [svgIcon]="data?.icon"></mat-icon>
    <div>
      <div [innerHTML]="data.body | translate" class="modal-upload__body"></div>
      <div class="file-upload">
        <form [formGroup]="formGroup">
          <mat-form-field>
            <ngx-mat-file-input formControlName="fileControl" [accept]="acceptedFormat" (change)="upload()">
              <mat-icon ngxMatFileInputIcon [svgIcon]="'icon-s-upload'|translate"></mat-icon>
            </ngx-mat-file-input>
            <mat-error *ngIf="this.formGroup.get('fileControl').errors?.sizeExceed">
              {{'form.errors.size-exceed' | translate}}
            </mat-error>
          </mat-form-field>
        </form>

      </div>
    </div>

    <div class="modal-upload__cta">
      <button *ngIf="data.ctaNegative" class="btn btn--secondary" [mat-dialog-close]="false">
        <span>{{ data.ctaNegative.label | translate }}</span>
      </button>
      <button *ngIf="data.ctaPositive" class="btn btn--primary" (click)="confirm()" [disabled]="this.enableButton() === false" [mat-dialog-close]="true">
        <span>{{ data.ctaPositive.label | translate  }}</span>
      </button>
    </div>
  </div>
</mat-dialog-content>
