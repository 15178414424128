import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ModalOrderInterface} from '../../core/models/modal-order.interface';
import { TaskOrderingItemInterface } from '@app/core/models/task-ordering-item.interface';
import { Subscription } from 'rxjs';
import { unsubscribe } from '@app/core/pure-functions/common-utilities';
import { getSearchTasksFilters, getTasksOrdering } from '@app/features/task/redux/task.selectors';
import { TaskSearchInterface } from '@app/core/models/task-search.interface';
import { Store } from '@ngrx/store';
import { TaskOrderingInterface } from '@app/core/models/task-ordering.interface';
import { SetTaskOrdering } from '@app/features/task/redux/task.actions';

@Component({
  selector: 'app-modal-order',
  templateUrl: './modal-order.component.html',
  styleUrls: ['./modal-order.component.scss']
})
export class ModalOrderComponent implements OnInit, OnDestroy {
  @Output() public orderEvent = new EventEmitter<MouseEvent>();

  iconOrder: boolean = true;

  tasksOrdering: TaskOrderingInterface = {};

  public subscriptions$: Subscription = new Subscription();

  constructor(public matDialogRef: MatDialogRef<ModalOrderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalOrderInterface,
              private store: Store) {

  }

  ngOnInit(): void {
    this.subscriptions$.add(this.store.select(getTasksOrdering).subscribe(
      (ordering: TaskOrderingInterface) => {
        if (ordering !== null) {
          this.tasksOrdering = JSON.parse(JSON.stringify(ordering));
        }
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions$);
  }

  close(): void {
    this.matDialogRef.close( false);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tasksOrdering.sorts, event.previousIndex, event.currentIndex);
  }

  setOrdering(): void {
    this.store.dispatch(SetTaskOrdering({ordering: this.tasksOrdering}));
    this.matDialogRef.close();
  }

  changeOrdering(item: TaskOrderingItemInterface): void {
    item.type = (item.type === 'ASC') ? 'DESC' : 'ASC';
  }

}
