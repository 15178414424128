import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../../environments/environment';

/**
 * usage: <div *debugViewer></div>
 */
@Directive({
  selector: '[appDebugViewer]'
})
export class DebugViewerDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    if (environment.dev){
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
