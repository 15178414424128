<mat-dialog-content>
  <div class="modal-radio-button">
    <form [formGroup]="form">
      <div class="modal-radio-button__header">
        <p>{{data.label}}</p>
        <button class="modal-btn-close" [mat-dialog-close]="false">
          <mat-icon svgIcon="icon-s-close"></mat-icon>
        </button>
      </div>
      <div class="modal-radio-button__content">
        <div class="form modal-radio-button__form">
          <mat-radio-group formControlName="choice" required="{{ data.required }}">
            <mat-radio-button [ngClass]="{'negative-outcome': (item.negativeOutcome === 'Yes')}"
              *ngFor="let item of data.details"
              [value]="item.value"
              [checked]="(item.selected === 'Si')">
              {{ item.value | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="modal-radio-button__footer">
        <button class="btn btn--secondary" (click)="reset()">
          <span>{{ 'modal-radio.reset' | translate  }}</span>
        </button>
        <button [disabled]="disableConfirm" class="btn btn--primary" (click)="confirm()">
          <span>{{ 'modal-radio.confirm' | translate  }}</span>
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
