import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { ModalErrorComponent } from '@app/shared/modal-error/modal-error.component';
import { AppState } from '@app/core/models/app-state.interface';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ErrorManagerService {
  dialogRef;

  constructor(public store: Store<AppState>,
              private dialog: MatDialog) {
  }

  public showErrorDialog(message: string): void {
    if (this.dialogRef) {
      return;
    }
    const dialogConfig = new MatDialogConfig<{ message: string }>();
    dialogConfig.data = { message: message };

    this.dialogRef = this.dialog.open(ModalErrorComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = undefined;
    });
  }

  public formatErrorMessage(err: HttpErrorResponse): string {
    console.log(err);
    let errorText =  err.message;
    if (err.error && typeof err.error === 'string') {
      errorText =  err.error;
    }
    return errorText;
  }

}
