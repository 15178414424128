import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {LogLevel} from '../models/log-level.enum';
import {environment} from '../../../environments/environment';
import {formatDate} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(@Inject(LOCALE_ID) private locale) {
  }

  writeLog(message: string, level: LogLevel): void {
    if (this.checkLevelLog(level)) {
      const stringLevel = Object.keys(LogLevel).filter(x => LogLevel[x] === level);
      const logMessage = stringLevel + ' - ' + formatDate(new Date(), 'yyyy-MM-dd', this.locale) + ' | ' + message;
    }
  }

  private checkLevelLog(level: LogLevel): boolean {
    let ret = false;
    if ((level >= environment.logLevel &&
      environment.logLevel !== LogLevel.OFF) ||
      environment.logLevel === LogLevel.ALL) {
      ret = true;
    }
    return ret;
  }
}
