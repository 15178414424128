import {Component, EventEmitter, Inject, Input, Output, Sanitizer} from '@angular/core';
import { CtaInterface } from '@app/core/models/cta.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventInterface } from '@app/core/models/event.interface';
import {sample} from 'rxjs/operators';

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  styleUrls: ['./calendar-modal.component.scss']
})
export class CalendarModalComponent {
  @Output() emitChange = new EventEmitter<any>();
  @Input() ctaPositive: CtaInterface;

  constructor(public matDialogRef: MatDialogRef<CalendarModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EventInterface) {
  }

  close(): void {
    this.matDialogRef.close(false);
  }

  get notesFormatted() {
    const firstStepReplacement = this.data.notes.replace('\\n', '<br>')
    const secondStepReplacement =  firstStepReplacement.replace('\\n', "<br>");
    const thirdStepReplacement = secondStepReplacement.replace('\\n', '<br>');
    const forthStepReplacement = thirdStepReplacement.replace('\\n', '<br>');
    const fifthStepReplacement = forthStepReplacement.replace('\\n', '<br>');
    const sixthStepReplacement = fifthStepReplacement.replace('\\n', '<br>');
    const seventhStepReplacement = sixthStepReplacement.replace('\\n', '<br>');
    return seventhStepReplacement
  }

  get descriptionFormatted() {
    return this.data.description.replace('\\n', '<br>');
  }


  get addressFormatted() {
    return this.data.plantAddress.replace('\\n', '<br>');
  }
}
