<div class="link-text">
  <ng-container *ngIf="!cta.modalSendEmail && !cta.modalSimple && !cta.name">
    <a [routerLink]="cta.url" class="{{this.styleClass}}">
      <p>{{cta.label|translate}}</p>
      <mat-icon [svgIcon]="cta.icon|translate"></mat-icon>
    </a>
  </ng-container>
  <ng-container *ngIf="cta.modalSendEmail">
    <a href="javascript:void(0);" (click)="openModalMail()" class="{{this.styleClass}}">
      <p>{{cta.label|translate}}</p>
      <mat-icon [svgIcon]="cta.icon|translate"></mat-icon>
    </a>
  </ng-container>
  <ng-container *ngIf="cta.modalSimple">
    <a href="javascript:void(0);" (click)="openModal()" class="{{this.styleClass}}">
      <p>{{cta.label|translate}}</p>
      <mat-icon [svgIcon]="cta.icon|translate"></mat-icon>
    </a>
  </ng-container>
</div>
