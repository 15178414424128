import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {HttpClientService} from '@app/core/services/http-client.service';
import {TaskResponseInterface} from '@app/core/models/task-response.interface';
import * as apiUrls from '@app/core/pure-functions/api-paths';
import * as TaskActions from './task.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {of} from 'rxjs';
import {ErrorManagerService} from '@app/core/services/error-manager.service';
import {Router} from '@angular/router';
import {AcceptAssignmentResponseInterface} from '@app/core/models/accept-assignment-response.interface';
import {LocalStorageKeys} from '@app/core/models/local-storage-keys.enum';
import {TaskInterface} from '@app/core/models/task.interface';
import {ProvinceInterface} from '@app/core/models/province.interface';
import {CityInterface} from '@app/core/models/city.interface';

@Injectable()
export class TaskEffects {

  tasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.GetTasks),
      switchMap((action => this.httpClientService.get<TaskResponseInterface>(apiUrls.TASKS)
          .pipe(
            map((response) => ({type: TaskActions.GET_TASKS_SUCCESS, response: response})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: TaskActions.GET_TASKS_ERROR, response: err});
            })
          )
      ))
    )
  );
  returnTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.ReturnTask),
      switchMap((action => this.httpClientService.post<AcceptAssignmentResponseInterface>(apiUrls.ASSIGNMENTS, action.request)
          .pipe(
            map((response) => ({type: TaskActions.RETURN_TASK_SUCCESS, response: response, request: action.request})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: TaskActions.RETURN_TASK_ERROR, response: err});
            })
          )
      ))
    )
  );
  returnTaskSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TaskActions.ReturnTaskSuccess),
        map((action) => {
          const localTask: TaskInterface = JSON.parse(localStorage.getItem(LocalStorageKeys.CURRENT_TASK));
          if (localTask && action.request.acceptances.find(item => item.assignmentId === localTask.id)) {
            localStorage.removeItem(LocalStorageKeys.CURRENT_TASK);
            localStorage.removeItem(LocalStorageKeys.CURRENT_REPORT);
          }
          this.router.navigateByUrl('/lista-interventi');
        })
      ),
    {dispatch: false}
  );
  rejectTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.RejectTasks),
      switchMap((action => this.httpClientService.post<AcceptAssignmentResponseInterface>(apiUrls.ASSIGNMENTS, action.request)
        .pipe(
          map((response) => {
              return {type: TaskActions.REJECT_TASKS_SUCCESS, response: response};
            } /*({type: TaskActions.REJECT_TASKS_SUCCESS, response: response}))*/,
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: TaskActions.REJECT_TASKS_ERROR, response: err});
            })
          )
        ))
      )
    ));
  rejectTasksSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TaskActions.RejectTasksSuccess),
        map((action) => {
          // console.log(action);
          // localStorage.removeItem(LocalStorageKeys.CURRENT_TASK);
          // localStorage.removeItem(LocalStorageKeys.CURRENT_REPORT);
          // this.router.navigateByUrl('/incarichi');
          window.location.reload();
        })
      ),
    {dispatch: false}
  );
  acceptTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.AcceptTasks),
      switchMap((action => this.httpClientService.post<AcceptAssignmentResponseInterface>(apiUrls.ASSIGNMENTS, action.request)
          .pipe(
            map((response) => ({type: TaskActions.ACCEPT_TASKS_SUCCESS, response: response})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: TaskActions.ACCEPT_TASKS_ERROR, response: err});
            })
          )
      ))
    )
  );
  getCurrentProvinces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.GetCurrentProvinces),
      switchMap((action => this.httpClientService.get<{provinces: ProvinceInterface[]}>(apiUrls.TASKS_PROVINCES)
          .pipe(
            map((response) => ({type: TaskActions.GET_CURRENT_PROVINCES_SUCCESS, response: response.provinces})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: TaskActions.ACCEPT_TASKS_ERROR, response: err});
            })
          )
      ))
    )
  );
  getCurrentCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.GetCurrentCities),
      switchMap((action => this.httpClientService.get<{cities: CityInterface[]}>(apiUrls.TASKS_CITIES, {province: action.province})
          .pipe(
            map((response) => ({type: TaskActions.GET_CURRENT_CITIES_SUCCESS, response: response.cities})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: TaskActions.ACCEPT_TASKS_ERROR, response: err});
            })
          )
      ))
    )
  );
  getCurrentCaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskActions.GetCurrentCaps),
      switchMap((action => this.httpClientService.get<{cap: CityInterface[]}>(apiUrls.TASKS_CAPS, {province: action.province, city: action.city})
          .pipe(
            map((response) => ({type: TaskActions.GET_CURRENT_CAPS_SUCCESS, response: response.cap})),
            catchError((err: HttpErrorResponse) => {
              const errorText = this.errorManager.formatErrorMessage(err);
              this.errorManager.showErrorDialog(errorText);
              return of({type: TaskActions.ACCEPT_TASKS_ERROR, response: err});
            })
          )
      ))
    )
  );
  acceptTasksSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TaskActions.AcceptTasksSuccess),
        map(() => {
          // this.router.navigateByUrl('/incarichi');
          window.location.reload();
        })
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions,
              private httpClientService: HttpClientService,
              private errorManager: ErrorManagerService,
              private router: Router) {
  }
}
