import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss']
})
export class DigitalSignatureComponent implements OnInit, AfterViewInit {
  signaturePad: SignaturePad;
  @Input() title: string;
  @Output() emit = new EventEmitter<any>();

  constructor() {

  }

  get signaturePadIsEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const container: HTMLElement = document.getElementById('signature-pad-container');
    const pad: HTMLElement = document.getElementById('signature-pad');
    pad.setAttribute('width', container.offsetWidth.toString());
    pad.setAttribute('height', container.offsetHeight.toString());
    this.signaturePad.clear();
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  ngOnInit(): void {
    this.signaturePad = new SignaturePad(document.querySelector('canvas'));
  }

  clearSignature(): void {
    this.signaturePad.clear();
  }

  saveSignature(): void {
    const dataUrlImage = this.signaturePad.toDataURL('image/png');
    this.emit.emit(dataUrlImage.split(',')[1]);
    // this.emit.emit(dataUrlImage);
  }

}
