import {createAction, props} from '@ngrx/store';
import {AssignmentResponseInterface} from '@app/core/models/assignment-response.interface';
import {AssignmentInterface} from '@app/core/models/assignment.interface';
import {AssignmentFilterInterface} from '@app/core/models/assignment-filter.interface';
import {TaskSearchInterface} from '@app/core/models/task-search.interface';


export const GET_ASSIGNMENTS = '[ASSIGNMENTS] Assignments';
export const GET_ASSIGNMENTS_SUCCESS = '[ASSIGNMENTS] Assignments success';
export const GET_ASSIGNMENTS_ERROR = '[ASSIGNMENTS] Assignments error';

export const SET_ASS_FILTERS = '[ASSIGNMENTS] Assignments Set Filters';

export const RESET_ASS_FILTERS = '[ASSIGNMENTS] Assignments Reset Filters';

export const GetAssignments = createAction(
  GET_ASSIGNMENTS,
);

export const GetAssignmentsSuccess = createAction(
  GET_ASSIGNMENTS_SUCCESS,
  props<{ response: AssignmentResponseInterface }>()
);
export const GetAssignmentsError = createAction(
  GET_ASSIGNMENTS_ERROR,
  props<{ response: any }>()
);

export const SetAssignmentsFilters = createAction(
  SET_ASS_FILTERS,
  props <{filters: TaskSearchInterface}>()
);

export const ResetAssignmentsFilters = createAction(
  RESET_ASS_FILTERS
);
