<header class="header">

  <ng-container *ngIf="!isLoggedIn">
    <div class="header--login">
      <div class="main-container">
        <img src="assets/images/eurofins-logo-positive1.png"/>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isLoggedIn">
    <div class="header--auth">
      <div class="main-container">
        <a [routerLink]="'/'">
          <img src="assets/images/eurofins-logo-negative.png"/>
        </a>
        <div class="header__user">
          <!--<span>{{this.username}}</span>
          <button class="btn" (click)="logout()">
            <mat-icon [svgIcon]="'icon-s-logout'"></mat-icon>
          </button>-->
          <button mat-button class="header__user__drop-button" [matMenuTriggerFor]="menu">{{this.username}}</button>
          <mat-menu #menu="matMenu">
            <button class="header__user__drop-items" mat-menu-item (click)="logout()">
              <mat-icon [svgIcon]="'icon-s-logout'"></mat-icon>
              LOGOUT
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </ng-container>

</header>
