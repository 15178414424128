import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-field-radio-button',
  templateUrl: './form-field-radio-button.component.html',
  styleUrls: ['./form-field-radio-button.component.scss']
})
export class FormFieldRadioButtonComponent implements OnInit {
  @Output() emitChange = new EventEmitter<any>();
  form: UntypedFormGroup;
  @Input() items: any;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      choice: new UntypedFormControl()
    });
  }

  reset() {
    this.form.controls.choice.reset();
  }

  selectValue(value) {
    this.emitChange.emit(value);
  }

}
