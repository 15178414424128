import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '@app/features/auth/services/auth.service';
import {InspectorTypeEnum} from '@app/core/models/inspector.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
      private authService: AuthService,
      private router: Router
  ) {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.inspector?.type === InspectorTypeEnum.INTERNO || this.authService.inspector?.type === InspectorTypeEnum.ESTERNO) {
      return true;
    }
    this.router.navigateByUrl('/403');
    return false;
  }
}
