import {Injectable} from '@angular/core';
import {CoordsInterface} from '@app/core/models/coords.interface';

@Injectable({
  providedIn: 'root'
})


export class MapService {
  geocoder = new google.maps.Geocoder();


  getLocation(cities: string[]): CoordsInterface[] {
    const coordsArr = [];
    const newArr = Object.assign([], coordsArr);
    // here i have to call google maps geocoder service
    for (const city of cities) {
      this.geocoder.geocode({address: city}, (results, status) => {
        if (status === 'OK') {
          const singleCoords = {
            position: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
            options: {
              icon: 'assets/images/icons/icon-l-pin.svg',
            }
          };
          newArr.push(singleCoords);
        }
      });
    }
    return newArr;
  }

  degr2rad(degr): number {
    return degr * Math.PI / 180;
  }

  rad2degr(rad): number {
    return rad * 180 / Math.PI;
  }


  getLatLngCenter(latLngInDegr): google.maps.LatLngLiteral {
    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;

    for (const coord of latLngInDegr) {
      const latDegr = this.degr2rad(coord.latitude);
      const lngDegr = this.degr2rad(coord.longitude);
      // sum of cartesian coordinates
      sumX += Math.cos(latDegr) * Math.cos(lngDegr);
      sumY += Math.cos(latDegr) * Math.sin(lngDegr);
      sumZ += Math.sin(latDegr);
    }

    const avgX = sumX / latLngInDegr.length;
    const avgY = sumY / latLngInDegr.length;
    const avgZ = sumZ / latLngInDegr.length;

    // convert average x, y, z coordinate to latitude and longtitude
    const lng = Math.atan2(avgY, avgX);
    const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    const lat = Math.atan2(avgZ, hyp);

    return ({lat: this.rad2degr(lat), lng: this.rad2degr(lng)});
  }

}
