import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { unsubscribe } from '@app/core/unsubscriber';
import { MatDialog } from '@angular/material/dialog';
import { ModalSimpleComponent } from '@app/shared/modal-simple/modal-simple.component';
import { ModalSimpleInterface } from '@app/core/models/modal-simple.interface';
import {Router} from '@angular/router';
import {AuthService} from '@app/features/auth/services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(
    private authService: AuthService,
  ) {
  }

  get username(): string {
    return this.authService.username;
  }

 get isLoggedIn(): boolean {
   return this.authService.isLoggedIn;
 }

  public subscriptions$: Subscription = new Subscription();


  logout(): void {
    this.authService.logout();
  }

}
