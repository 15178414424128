import {Action, createReducer, on} from '@ngrx/store';
import * as ReportActions from './report.actions';
import {ReportInterface} from '@app/core/models/report.interface';
import {SectionInterface} from '@app/core/models/section.interface';
import {FormFieldBase} from '@app/core/models/form-field-base';

export interface ReportState {
  currentReport: ReportInterface;
  currentSection: SectionInterface;
  currentSectionOriginal: SectionInterface;
  currentSignatureFieldLabel: string;
  currentSignatureField: FormFieldBase<string>;
  preview: Blob;
  previousPreview: Blob;
}

export const initialState: ReportState = {
  currentReport: null,
  currentSection: null,
  currentSectionOriginal: null,
  currentSignatureFieldLabel: '',
  currentSignatureField: null,
  preview: null,
  previousPreview: null,
};


const reportReducer = createReducer(
  initialState,
  on(ReportActions.GetReportSuccess, (state, {response}) => {
    return ({
      ...state,
      currentReport: response,
    });
  }),
  on(ReportActions.SaveCurrentReport, (state, {report}) => {
    return ({
      ...state,
      currentReport: report,
    });
  }),
  on(ReportActions.SetCurrentReportSection, (state, {section}) => {
    return ({
      ...state,
      currentSection: section,
      currentSectionOriginal: section,
    });
  }),
  on(ReportActions.SaveTemporaryCurrentReportSection, (state, {section}) => {
    return ({
      ...state,
      currentSection: section,
    });
  }),
  on(ReportActions.ResetCurrentReportSection, (state) => {
    return ({
      ...state,
      currentSection: state.currentSectionOriginal,
    });
  }),
  on(ReportActions.SetCurrentSignatureField, (state, {field}) => {
    return ({
      ...state,
      currentSignatureField: field,
    });
  }),
  on(ReportActions.SaveUpdatedSignatureField, (state, {field}) => {
    return ({
      ...state,
      currentSignatureField: field,
    });
  }),
  on(ReportActions.GetReportPreviewSuccess, (state, {response}) => {
    return ({
      ...state,
      preview: response,
    });
  }),
  on(ReportActions.ClearReportPreview, (state) => {
    return ({
      ...state,
      preview: null,
    });
  }),
  on(ReportActions.GetPreviousReportPreviewSuccess, (state, {response}) => {
    return ({
      ...state,
      previousPreview: response,
    });
  }),
  on(ReportActions.ClearPreviousReportPreview, (state) => {
    return ({
      ...state,
      previousPreview: null,
    });
  }),
);

export function reducer(state: ReportState | undefined, action: Action): ReportState {
  return reportReducer(state, action);
}
