import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInterceptor } from '@app/core/services/http-client.interceptor';
import { SharedModule } from '@app/shared/shared.module';
import { AngularMaterialModule } from '@app/shared/material/angular-material.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DebugViewerDirective } from '@app/core/directives/debug-viewer.directive';
import { DigitOnlyDirective } from '@app/core/directives/digit-only.directive';



@NgModule({
  declarations: [DebugViewerDirective, DigitOnlyDirective],
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    SharedModule,
    AngularMaterialModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    DebugViewerDirective,
    DigitOnlyDirective
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
