import {UntypedFormControl} from '@angular/forms';

export class FieldConfigurationClass {
  header: string;
  label: string;
  placeholder: string;
  required: boolean;
  iconName: string;
  buttonIconName: string;
  buttonName: string;
  formControl: UntypedFormControl;
  type?: string;
  name: string;
  readonly: boolean;
  enabled = true;
  maxLength = 32768;
}
