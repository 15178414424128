import {environment} from '../../../environments/environment';

export const PREFIX_BE = environment.host;
export const PREFIX_API = 'api/v1/';
export const BASE_URL = PREFIX_BE + PREFIX_API;

// Login API
export const LOGIN = BASE_URL + 'login';
export const CHANGE_PSW = BASE_URL + 'password/change';
export const RESET_PWS = BASE_URL + 'password/reset';

// Operation API
export const ASSIGNMENTS = BASE_URL + 'assignments';
export const TASKS = BASE_URL + 'tasks';
export const TASKS_PLANNING = BASE_URL + 'tasks/planned';
export const TASKS_PROVINCES = BASE_URL + 'tasks/provinces';
export const TASKS_CITIES = BASE_URL + 'tasks/cities';
export const TASKS_CAPS = BASE_URL + 'tasks/caps';
export const INSPECTOR_TYPE = BASE_URL + 'inspector/type';

// Reports API
export const REPORTS = BASE_URL + 'reports/{0}'; // {1} is idIntervento
export const REPORTS_SEND_MAIL = BASE_URL + 'reports/mail/{0}'; // {1} is idIntervento
export const REPORTS_PREVIEW = BASE_URL + 'reports/preview';
export const REPORTS_PREVIEW_DATA_SHEET = BASE_URL + 'reports/previewtech';
export const REPORTS_SECTIONS = BASE_URL + 'reports/sections/{0}'; // {1} is idIntervento
export const UPLOAD_ATTACHMENT = BASE_URL + 'reports/uploadAttachment';
export const DOWNLOAD_ATTACHMENT = BASE_URL + 'reports/downloadAttachment';
