import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LinkWithSubtitleInterface} from '../../core/models/link-with-subtitle.interface';

@Component({
  selector: 'app-link-with-subtitle',
  templateUrl: './link-with-subtitle.component.html',
  styleUrls: ['./link-with-subtitle.component.scss']
})
export class LinkWithSubtitleComponent {
  @Input() cta: LinkWithSubtitleInterface;

  @Output() emitCtaClick = new EventEmitter<any>();

  constructor() {
  }


  clickCta(event): void {
    this.emitCtaClick.emit(this.cta.index);
  }

}
