import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {CtaInterface} from '@app/core/models/cta.interface';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ModalSimpleInterface} from '@app/core/models/modal-simple.interface';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ModalPdfViewerComponent} from '@app/shared/modal-pdf-viewer/modal-pdf-viewer.component';

@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss']
})
export class ModalUploadComponent {
  @Output() emitChange = new EventEmitter<any>();
  @Input() ctaNegative: CtaInterface;
  @Input() ctaPositive: CtaInterface;

  acceptedFormat = '.pdf';
  sizeExceed = false;
  file?: string;
  formGroup: UntypedFormGroup;



  constructor(public matDialogRef: MatDialogRef<ModalUploadComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModalSimpleInterface) {
    this.formGroup = new UntypedFormGroup({
        fileControl: new UntypedFormControl('', [Validators.required])
      });
  }

  upload(): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      if (e.target.result.length * (2 * 3 / 4)  > 2 ** 21) {
        this.formGroup.get('fileControl').setErrors({'sizeExceed': true});
      } else {
        this.sizeExceed = false;
        this.file = e.target.result.split(',')[1];
      }
    };
    reader.readAsDataURL(this.formGroup.get('fileControl').value);
  }


  confirm(): void {
    this.matDialogRef.close({
      value: this.file
    });
  }

  enableButton(): boolean {
    return this.formGroup.get('fileControl').valid;
  }

  close(): void {
     this.matDialogRef.close(false);
  }
}
