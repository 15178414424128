import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FieldConfigurationData } from '@app/core/models/field-configuration-data';
import {unsubscribe} from '@app/core/pure-functions/common-utilities';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  getCities,
  getClassifications,
  getDivisions,
  getProvinces, getSearchTasksFilters, getTasks,
  getTaskTypes
} from '@app/features/task/redux/task.selectors';
import { Store } from '@ngrx/store';
import { GetTasksFiltered, ResetTasksFiltered } from '@app/features/task/redux/task.actions';
import { TaskSearchInterface } from '@app/core/models/task-search.interface';
import { BoxAlertInterface } from '@app/core/models/box-alert.interface';
import * as moment from 'moment';
import {ResetAssignmentsFilters, SetAssignmentsFilters} from '@app/features/assignment/redux/assignment.actions';
import {
  getAssignmentCities,
  getAssignmentDivisions,
  getAssignmentFilters,
  getAssignmentProvinces, getAssignmentsClassifications, getAssignmentsTaskTypes
} from '@app/features/assignment/redux/assignment.selectors';

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html',
  styleUrls: ['./modal-search.component.scss']
})
export class ModalSearchComponent implements OnInit, OnDestroy {
  searchForm: UntypedFormGroup;
  planningDateRange = new UntypedFormGroup({
    startRangeDate: new UntypedFormControl(),
    endRangeDate: new UntypedFormControl()
  });
  endDateRange = new UntypedFormGroup({
    startRangeDate: new UntypedFormControl(),
    endRangeDate: new UntypedFormControl()
  });
  idModel: FieldConfigurationData<string>;
  administratorModel: FieldConfigurationData<string>;
  customerNameModel: FieldConfigurationData<string>;
  provinceModel: FieldConfigurationData<string>;
  cityModel: FieldConfigurationData<string>;
  fullAddressModel: FieldConfigurationData<string>;
  reportHeaderModel: FieldConfigurationData<string>;
  systemIdModel: FieldConfigurationData<string>;
  factoryNumberModel: FieldConfigurationData<string>;
  maintainerNameModel: FieldConfigurationData<string>;
  divisionModel: FieldConfigurationData<string>;
  taskTypeModel: FieldConfigurationData<string>;
  classificationModel: FieldConfigurationData<string>;
  descriptionModel: FieldConfigurationData<string>;

  private filtersValues: TaskSearchInterface = {
    planningDate: '',
    endDate: '',
    id: '',
    administrator: '',
    customerName: '',
    province: '',
    city: '',
    fullAddress: '',
    reportHeader: '',
    systemId: '',
    factoryNumber: '',
    maintainerName: '',
    division: '',
    taskType: '',
    classification: '',
    description: '',
  };

  public subscriptions$: Subscription = new Subscription();


  constructor(public matDialogRef: MatDialogRef<ModalSearchComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TaskSearchInterface,
              private formBuilder: UntypedFormBuilder,
              private store: Store,
              private cdRef: ChangeDetectorRef) {


    this.idModel = new FieldConfigurationData<string>();
    this.idModel.formControl = new UntypedFormControl();
    this.administratorModel = new FieldConfigurationData<string>();
    this.administratorModel.formControl = new UntypedFormControl();
    this.customerNameModel = new FieldConfigurationData<string>();
    this.customerNameModel.formControl = new UntypedFormControl();
    this.provinceModel = new FieldConfigurationData<string>();
    this.provinceModel.formControl = new UntypedFormControl();
    this.provinceModel.required = false;
    this.cityModel = new FieldConfigurationData<string>();
    this.cityModel.formControl = new UntypedFormControl();
    this.cityModel.required = false;
    this.fullAddressModel = new FieldConfigurationData<string>();
    this.fullAddressModel.formControl = new UntypedFormControl();
    this.reportHeaderModel = new FieldConfigurationData<string>();
    this.reportHeaderModel.formControl = new UntypedFormControl();
    this.systemIdModel = new FieldConfigurationData<string>();
    this.systemIdModel.formControl = new UntypedFormControl();
    this.factoryNumberModel = new FieldConfigurationData<string>();
    this.factoryNumberModel.formControl = new UntypedFormControl();
    this.maintainerNameModel = new FieldConfigurationData<string>();
    this.maintainerNameModel.formControl = new UntypedFormControl();
    this.divisionModel = new FieldConfigurationData<string>();
    this.divisionModel.formControl = new UntypedFormControl();
    this.divisionModel.required = false;
    this.taskTypeModel = new FieldConfigurationData<string>();
    this.taskTypeModel.formControl = new UntypedFormControl();
    this.taskTypeModel.required = false;
    this.classificationModel = new FieldConfigurationData<string>();
    this.classificationModel.formControl = new UntypedFormControl();
    this.classificationModel.required = false;
    this.descriptionModel = new FieldConfigurationData<string>();
    this.descriptionModel.formControl = new UntypedFormControl();

    this.searchForm = this.formBuilder.group({
      // planningDate: this.planningDateModel.formControl,
      // endDate: this.endDateModel.formControl,
      planningDate: this.planningDateRange,
      endDate: this.endDateRange,
      id: new UntypedFormControl('', []),
      administrator: new UntypedFormControl('', []),
      customerName: new UntypedFormControl('', []),
      province: this.provinceModel.formControl,
      city: this.cityModel.formControl,
      fullAddress: new UntypedFormControl('', []),
      reportHeader: new UntypedFormControl('', []),
      systemId: new UntypedFormControl('', []),
      factoryNumber: new UntypedFormControl('', []),
      maintainerName: new UntypedFormControl('', []),
      division: this.divisionModel.formControl,
      taskType: this.taskTypeModel.formControl,
      classification: this.classificationModel.formControl,
      description: this.descriptionModel.formControl,
    });
  }

  ngOnInit(): void {
    // const emptySelectItem: OptionValueInterface<string> = {
    //   label: '',
    //   value: ''
    // };
    this.subscriptions$.add(this.store.select(getProvinces).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
        // return value;
      }))
    ).subscribe(provinces => {
      // provinces.unshift(emptySelectItem);
      if (!this.data.assignments) {
        this.provinceModel.dataList = provinces;
      }
    }));
    this.subscriptions$.add(this.store.select(getAssignmentProvinces).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
        // return value;
      }))
    ).subscribe(provinces => {
      // provinces.unshift(emptySelectItem);
      if (this.data.assignments) {
        this.provinceModel.dataList = provinces;
      }
    }));
    this.subscriptions$.add(this.store.select(getCities).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
        // return value;
      }))
    ).subscribe(cities => {
      // cities.unshift(emptySelectItem);
      if (!this.data.assignments) {
        this.cityModel.dataList = cities;
      }
    }));
    this.subscriptions$.add(this.store.select(getAssignmentCities).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
        // return value;
      }))
    ).subscribe(cities => {
      // cities.unshift(emptySelectItem);
      if (this.data.assignments) {
        this.cityModel.dataList = cities;
      }
    }));
    this.subscriptions$.add(this.store.select(getDivisions).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
        // return value;
      }))
    ).subscribe(divisions => {
      // divisions.unshift(emptySelectItem);
      if (!this.data.assignments) {
        this.divisionModel.dataList = divisions;
      }
    }));
    this.subscriptions$.add(this.store.select(getAssignmentDivisions).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
        // return value;
      }))
    ).subscribe(divisions => {
      // divisions.unshift(emptySelectItem);
      if (this.data.assignments) {
        this.divisionModel.dataList = divisions;
      }
    }));
    this.subscriptions$.add(this.store.select(getTaskTypes).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
      }))
    ).subscribe(taskTypes => {
      if (!this.data.assignments) {
        this.taskTypeModel.dataList = taskTypes;
      }
    }));
    this.subscriptions$.add(this.store.select(getAssignmentsTaskTypes).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
      }))
    ).subscribe(taskTypes => {
      if (this.data.assignments) {
        this.taskTypeModel.dataList = taskTypes;
      }
    }));
    this.subscriptions$.add(this.store.select(getClassifications).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
      }))
    ).subscribe(classifications => {
      if (!this.data.assignments) {
        this.classificationModel.dataList = classifications;
      }
    }));
    this.subscriptions$.add(this.store.select(getAssignmentsClassifications).pipe(
      filter(data => data !== null),
      map(values => values.map(item => {
        return { label: item, value: item };
      }))
    ).subscribe(classifications => {
      if (this.data.assignments) {
        this.classificationModel.dataList = classifications;
      }
    }));

    this.subscriptions$.add(this.store.select(getSearchTasksFilters).pipe(
      filter(data => data !== null),
    ).subscribe(filters => {
      if (!this.data.assignments){
        this.filtersValues = filters;
      }
    }));

    this.subscriptions$.add(this.store.select(getAssignmentFilters).pipe(
      filter(data => data !== null),
    ).subscribe(filters => {
      if (this.data.assignments){
        this.filtersValues = filters;
      }
    }));

    if (this.filtersValues) {
      if (this.filtersValues.planningDate) {
        const filterPlanningDateStart = moment(this.filtersValues.planningDate.split(' ')[0], 'DD/MM/YYYY');
        const filterPlanningDateEnd = moment(this.filtersValues.planningDate.split(' ')[1], 'DD/MM/YYYY');

        this.planningDateRange = new UntypedFormGroup({
          startRangeDate: new UntypedFormControl(filterPlanningDateStart),
          endRangeDate: new UntypedFormControl(filterPlanningDateEnd)
        });
      }
      if (this.filtersValues.endDate) {
        const filterEndDateStart = moment(this.filtersValues.endDate.split(' ')[0], 'DD/MM/YYYY');
        const filterEndDateEnd = moment(this.filtersValues.endDate.split(' ')[1], 'DD/MM/YYYY');

        this.endDateRange = new UntypedFormGroup({
          startRangeDate: new UntypedFormControl(filterEndDateStart),
          endRangeDate: new UntypedFormControl(filterEndDateEnd)
        });
      }
      this.searchForm.controls.id.patchValue(this.filtersValues.id);
      this.searchForm.controls.administrator.patchValue(this.filtersValues.administrator);
      this.searchForm.controls.customerName.patchValue(this.filtersValues.customerName);
      this.searchForm.controls.province.setValue({label: this.filtersValues.province, value: this.filtersValues.province});
      this.searchForm.controls.city.setValue({label: this.filtersValues.city, value: this.filtersValues.city});
      this.searchForm.controls.fullAddress.patchValue(this.filtersValues.fullAddress);
      this.searchForm.controls.reportHeader.patchValue(this.filtersValues.reportHeader);
      this.searchForm.controls.systemId.patchValue(this.filtersValues.systemId);
      this.searchForm.controls.factoryNumber.patchValue(this.filtersValues.factoryNumber);
      this.searchForm.controls.maintainerName.patchValue(this.filtersValues.maintainerName);
      this.searchForm.controls.division.setValue({label: this.filtersValues.division, value: this.filtersValues.division});
      this.searchForm.controls.taskType.setValue({label: this.filtersValues.taskType, value: this.filtersValues.taskType});
      this.searchForm.controls.classification.setValue({label: this.filtersValues.classification, value: this.filtersValues.classification});
      this.searchForm.controls.description.patchValue(this.filtersValues.description);
    }

    this.cdRef.detectChanges();

  }

  search(): void {
    console.log('calling search');
    const planningDateStart = (this.planningDateRange.value.startRangeDate) ? this.planningDateRange.value.startRangeDate.format('DD/MM/YYYY') : '';
    const planningDateEnd = (this.planningDateRange.value.endRangeDate) ? this.planningDateRange.value.endRangeDate.format('DD/MM/YYYY') : '';
    const plannindDateRange = (planningDateStart && planningDateEnd) ? planningDateStart + ' ' + planningDateEnd : '';

    const endDateDateStart = (this.endDateRange.value.startRangeDate) ? this.endDateRange.value.startRangeDate.format('DD/MM/YYYY') : '';
    const endDateDateEnd = (this.endDateRange.value.endRangeDate) ? this.endDateRange.value.endRangeDate.format('DD/MM/YYYY') : '';
    const endDateDateRange = (endDateDateStart && endDateDateEnd) ? endDateDateStart + ' ' + endDateDateEnd : '';

    const filtersValues: TaskSearchInterface = {
      planningDate: plannindDateRange.length !== 0 ? plannindDateRange : undefined,
      endDate: endDateDateRange.length !== 0 ? endDateDateRange : undefined,
      id: this.searchForm.controls.id.value,
      administrator: this.searchForm.controls.administrator.value,
      customerName: this.searchForm.controls.customerName.value,
      province: (this.searchForm.controls.province.value) ? this.searchForm.controls.province.value.value : '',
      city: (this.searchForm.controls.city.value) ? this.searchForm.controls.city.value.value : '',
      fullAddress: this.searchForm.controls.fullAddress.value,
      reportHeader: this.searchForm.controls.reportHeader.value,
      systemId: this.searchForm.controls.systemId.value,
      factoryNumber: this.searchForm.controls.factoryNumber.value,
      maintainerName: this.searchForm.controls.maintainerName.value,
      division: (this.searchForm.controls.division.value) ? this.searchForm.controls.division.value.value : '',
      taskType: (this.searchForm.controls.taskType.value) ? this.searchForm.controls.taskType.value.value : '',
      classification: (this.searchForm.controls.classification.value) ? this.searchForm.controls.classification.value.value : '',
      description: this.searchForm.controls.description.value,
    };
    if (this.data.assignments) {
      this.store.dispatch(SetAssignmentsFilters({filters: filtersValues}));
    } else {
      this.store.dispatch(GetTasksFiltered({ filters: filtersValues}));
    }
    this.matDialogRef.close();
  }

  get enableSearch(): boolean {
    return false;
  }

  resetSearchFields(): void {
    this.searchForm.reset();
    if (this.data.assignments) {
      this.store.dispatch(ResetAssignmentsFilters());
    } else {
      this.store.dispatch(ResetTasksFiltered());
    }
    this.matDialogRef.close();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions$);
  }

}
