import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormFieldBase} from '@app/core/models/form-field-base';
import {ModalCheckboxesInterface} from '@app/core/models/modal-checkboxes.interface';

@Component({
  selector: 'app-modal-radio-button',
  templateUrl: './modal-checkboxes.component.html',
  styleUrls: ['./modal-checkboxes.component.scss']
})
export class ModalCheckboxesComponent {
  @Output() emitChange = new EventEmitter<any>();
  form: UntypedFormGroup;
  @Input() items: ModalCheckboxesInterface;

  constructor(public matDialogRef: MatDialogRef<ModalCheckboxesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FormFieldBase<string>,
              private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      checkboxes: new UntypedFormArray([]),
    });
    this.addCheckBoxes();
  }

  get checkboxesFormArray(): UntypedFormArray {
    return this.form.controls.checkboxes as UntypedFormArray;
  }


  reset(): void {
    this.form.reset();
  }

  confirm(): void {
    const selectedCheckboxes = this.form.value.checkboxes
      .map((checked, i) => checked ? this.data.details[i].number : null)
      .filter(v => v !== null);
    this.matDialogRef.close({
      value: selectedCheckboxes,
      rowNumber: this.data.rowNumber
    });
  }

  private addCheckBoxes(): void {
    this.data.details.forEach((detail) => {
      (detail.selected === 'Si') ? this.checkboxesFormArray.push(new UntypedFormControl(true)) : this.checkboxesFormArray.push(new UntypedFormControl(false));
    });
  }
}
