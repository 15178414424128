import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {CalendarDateFormatter, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {CustomDateFormatter} from '../../custom-date-formatter.providers';
import {CalendarService} from '@app/features/calendar/services/calendar.service';
import {CtaInterface} from '@app/core/models/cta.interface';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Subject, Subscription} from 'rxjs';
import {getEvents} from '@app/features/calendar/redux/calendar.selectors';
import {filter} from 'rxjs/operators';
import {GetEvents} from '@app/features/calendar/redux/calendar.actions';
import {EventResponseInterface} from '@app/core/models/event-response.interface';
import {MatDialog} from '@angular/material/dialog';
import {EventInterface} from '@app/core/models/event.interface';
import {CalendarModalComponent} from '@app/features/calendar/containers/calendar-modal/calendar-modal.component';
import {GetTasks} from '@app/features/task/redux/task.actions';
import {getTasksUnfiltered} from '@app/features/task/redux/task.selectors';
import {TaskInterface} from '@app/core/models/task.interface';


export interface CalendarInterface {
  backCta: CtaInterface;
}

@Component({
  selector: 'app-calendar',
  templateUrl: 'calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarComponent implements OnInit, OnDestroy {
  public subscriptions$: Subscription = new Subscription();
  selectedCategory: string;
  view: CalendarView = window.innerWidth >= 1000 ? CalendarView.Week : CalendarView.Day;
  viewDate: Date = new Date();
  locale = 'it';
  calendarData: CalendarInterface = {
    backCta: {
      label: '',
      url: ''
    }
  };
  // exclude weekends
  excludeDays: number[] = [0, 6];
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  assignedTasks: TaskInterface[] =  [];

  refresh: Subject<any> = new Subject();
  events =  [];

  constructor(
    private calendarService: CalendarService,
    private router: Router,
    private store: Store,
    private dialog: MatDialog
  ) {
    // i retrieve events data from state after dispatching
    this.subscriptions$.add(this.store.select(getEvents).pipe(
      filter((events) => !!events)
    ).subscribe((events: EventResponseInterface[]) => {
      this.events = this.calendarService.getAllEvents(events);
      this.store.dispatch(GetTasks());
      // @ts-ignore
      this.refresh.next();
    }));

    this.subscriptions$.add(this.store.select(getTasksUnfiltered).pipe(
      filter((tasks) => !!tasks)
    ).subscribe((tasks) => {
      this.assignedTasks = tasks;
      this.events = this.calendarService.checkifAssigned(this.events, this.assignedTasks);
    }));

    this.store.dispatch(GetEvents());

  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.view = window.innerWidth >= 1000 ? CalendarView.Week : CalendarView.Day;
  }

  goBack(value: boolean): void {
    if (value) {
      this.router.navigateByUrl(this.calendarData.backCta.url);
    }
  }

  openDetails(content: EventInterface): void {
    const dialogRef = this.dialog.open(CalendarModalComponent, {
      data: content
    });
  }

  eventClicked(event: EventInterface): void {
    event.statusClass = (event.statusClass === '') ? 'open' : '';
  }

  ngOnInit(): void {
    this.calendarData = {
      backCta: {
        label: '',
        url: '/'
      }
    };
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
