<div class="table-row">
  <div class="table-row__container">
    <div class="table-row__header">
      <div class="table-row__header-row">
        <app-form-field-checkbox [selected]="item.selected" (emitChange)="emitChange($event)"></app-form-field-checkbox>
        <span>{{'table-row.intervento'|translate}}:</span>
        <p>{{item.taskNumber}}</p>
      </div>
      <div class="table-row__header-row">
        <span>{{'table-row.user'|translate}}:</span>
        <p>{{item.username}}</p>
      </div>
    </div>
    <div class="table-row__body">
      <app-table-cell *ngFor="let item of item.items" [item]=item ></app-table-cell>
    </div>
  </div>
</div>
