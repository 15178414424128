import {Component, EventEmitter, Inject, Input, OnDestroy, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FormFieldBase} from '@app/core/models/form-field-base';
import {ModalCheckboxesInterface} from '@app/core/models/modal-checkboxes.interface';
import {Subscription} from 'rxjs';
import {unsubscribe} from '@app/core/pure-functions/common-utilities';

@Component({
  selector: 'app-modal-radio-button',
  templateUrl: './modal-checkboxes-note.component.html',
  styleUrls: ['./modal-checkboxes-note.component.scss']
})
export class ModalCheckboxesNoteComponent implements OnDestroy {
  @Output() emitChange = new EventEmitter<any>();
  form: UntypedFormGroup;
  @Input() items: ModalCheckboxesInterface;

  public subscriptions$: Subscription = new Subscription();

  constructor(public matDialogRef: MatDialogRef<ModalCheckboxesNoteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FormFieldBase<string>,
              private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      checkboxes: new UntypedFormArray([]),
    })
    this.addCheckBoxes();
    console.log(data.maxLength);
    // console.log(this.checkboxesFormArray);

    this.checkboxesFormArray.controls.forEach((checkboxGroup) => {
      // console.log(checkboxGroup);
      this.subscriptions$.add(checkboxGroup.get('checkbox').valueChanges.subscribe((value) => {
        (value) ? checkboxGroup.get('note').enable() : checkboxGroup.get('note').disable();
      }));
    });
  }

  get checkboxesFormArray(): UntypedFormArray {
    return this.form.controls.checkboxes as UntypedFormArray;
  }


  ngOnDestroy(): void {
    unsubscribe(this.subscriptions$);
  }

  reset(): void {
    this.form.reset();
  }

  confirm(): void {
    this.matDialogRef.close({
      value: this.form.value.checkboxes,
      rowNumber: this.data.rowNumber
    });
  }

  private addCheckBoxes(): void {
    this.data.details.forEach((detail) => {
      const checkBoxNoteGroup = new UntypedFormGroup({
        checkbox: (detail.selected === 'Si') ? new UntypedFormControl(true) : new UntypedFormControl(false),
        note: new UntypedFormControl(detail.note)
      });
      (detail.selected === 'Si') ? checkBoxNoteGroup.controls.note.enable() : checkBoxNoteGroup.controls.note.disable();
      this.checkboxesFormArray.push(checkBoxNoteGroup);
    });
  }
}
