export const svgIcons = [
    {
        label: 'icon-s-search',
        path: '/assets/images/icons/icon-s-search.svg'
    },
    {
        label: 'icon-s-plus',
        path: '/assets/images/icons/icon-s-plus.svg'
    },
    {
        label: 'icon-s-pin',
        path: '/assets/images/icons/icon-s-pin.svg'
    },
    {
        label: 'icon-s-paper-lane',
        path: '/assets/images/icons/icon-s-paper-lane.svg'
    },
    {
        label: 'icon-s-order-increasing',
        path: '/assets/images/icons/icon-s-order-increasing.svg'
    },
    {
        label: 'icon-s-order-decreasing',
        path: '/assets/images/icons/icon-s-order-decreasing.svg'
    },
    {
        label: 'icon-s-menu',
        path: '/assets/images/icons/icon-s-menu.svg'
    },
    {
        label: 'icon-s-map',
        path: '/assets/images/icons/icon-s-map.svg'
    },
    {
        label: 'icon-s-logout',
        path: '/assets/images/icons/icon-s-logout.svg'
    },
    {
        label: 'icon-s-info',
        path: '/assets/images/icons/icon-s-info.svg'
    },
    {
        label: 'icon-s-filter',
        path: '/assets/images/icons/icon-s-filter.svg'
    },
    {
        label: 'icon-s-eye',
        path: '/assets/images/icons/icon-s-eye.svg'
    },
    {
        label: 'icon-s-corner-down-left',
        path: '/assets/images/icons/icon-s-corner-down-left.svg'
    },
    {
        label: 'icon-s-close',
        path: '/assets/images/icons/icon-s-close.svg'
    },
    {
        label: 'icon-s-chevron-up',
        path: '/assets/images/icons/icon-s-chevron-up.svg'
    },
    {
        label: 'icon-s-chevron-right',
        path: '/assets/images/icons/icon-s-chevron-right.svg'
    },
    {
        label: 'icon-s-chevron-left',
        path: '/assets/images/icons/icon-s-chevron-left.svg'
    },
    {
        label: 'icon-s-chevron-down',
        path: '/assets/images/icons/icon-s-chevron-down.svg'
    },
    {
        label: 'icon-s-check',
        path: '/assets/images/icons/icon-s-check.svg'
    },
    {
        label: 'icon-s-calendar',
        path: '/assets/images/icons/icon-s-calendar.svg'
    },
    {
        label: 'icon-s-arrow-right',
        path: '/assets/images/icons/icon-s-arrow-right.svg'
    },
    {
        label: 'icon-s-alert',
        path: '/assets/images/icons/icon-s-alert.svg'
    },
    {
        label: 'icon-l-checklist',
        path: '/assets/images/icons/icon-l-checklist.svg'
    },
    {
        label: 'icon-l-calendar',
        path: '/assets/images/icons/icon-l-calendar.svg'
    },
    {
        label: 'icon-l-assignment',
        path: '/assets/images/icons/icon-l-assignment.svg'
    },
    {
        label: 'icon-s-minus',
        path: '/assets/images/icons/icon-s-minus.svg'
    },
    {
        label: 'icon-s-edit',
        path: '/assets/images/icons/icon-s-edit.svg',
    },
    {
      label: 'icon-s-upload',
      path: '/assets/images/icons/icon-s-upload.svg',
    },
    {
        label: 'icon-backspace',
       path: '/assets/images/icons/icon-backspace.svg',
    }
];
