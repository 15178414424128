import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AppState} from '@app/core/models/app-state.interface';
import {AssignmentStateInterface} from '@app/core/models/assignment-state.interface';
import {AssignmentInterface} from '@app/core/models/assignment.interface';
import {TaskSearchInterface} from '@app/core/models/task-search.interface';

export const selectAssignmentsState = createFeatureSelector<AppState, AssignmentStateInterface>('assignments');

export const getAssignments = createSelector(
  selectAssignmentsState,
  (state: AssignmentStateInterface): AssignmentInterface[] => state.assignments
);

export const getAssignmentsFiltered = createSelector(
  selectAssignmentsState,
  (state: AssignmentStateInterface): AssignmentInterface[] => state.filteredAssignments
);

export const getAssignmentFilters = createSelector(
  selectAssignmentsState,
  (state: AssignmentStateInterface): TaskSearchInterface => state.filters
);

export const getAssignmentProvinces = createSelector(
  selectAssignmentsState,
  (state: AssignmentStateInterface): string[] => {
    const provincesList = [];
    state.assignments.forEach((assignment) => {
      if (!provincesList.includes(assignment.address.province)) {
        provincesList.push(assignment.address.province);
      }
    });
    return provincesList;
  }
);

export const getAssignmentCities = createSelector(
  selectAssignmentsState,
  (state: AssignmentStateInterface): string[] => {
    const citiesList = [];
    state.assignments.forEach((assignment) => {
      if (!citiesList.includes(assignment.address.city)) {
        citiesList.push(assignment.address.city);
      }
    });
    return citiesList;
  }
);

export const getAssignmentDivisions = createSelector(
  selectAssignmentsState,
  (state: AssignmentStateInterface): string[] => {
    const divisionsList = [];
    state.assignments.forEach((assignment) => {
      if (!divisionsList.includes(assignment.division)) {
        divisionsList.push(assignment.division);
      }
    });
    return divisionsList;
  }
);

export const getAssignmentsTaskTypes = createSelector(
  selectAssignmentsState,
  (state: AssignmentStateInterface): string[] => {
    const taskTypesList = [];
    state.assignments.forEach((assignment) => {
      if (!taskTypesList.includes(assignment.taskType)) {
        taskTypesList.push(assignment.taskType);
      }
    });
    return taskTypesList;
  }
);

export const getAssignmentsClassifications = createSelector(
  selectAssignmentsState,
  (state: AssignmentStateInterface): string[] => {
    const classificationList = [];
    state.assignments.forEach((assignment) => {
      if (!classificationList.includes(assignment.classification)) {
        classificationList.push(assignment.classification);
      }
    });
    return classificationList;
  }
);
